import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import SearchSelect from 'components/OrderSearchSelector';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect } from 'react';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input, Table } from 'reactstrap';
import { getOrderOptions } from 'redux/actions/data/orderActions';
import { getRechargeList } from 'redux/actions/data/orderReturnActions';
import CommonUtil from 'utils/CommonUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const TableList = ({
                     onChangePage,
                     setIsDiscountActivityModalOpen,
                     setIsDiscountModalOpen,
                     setCurrentDiscountInfo,
                   }) => {
  const { loading, rechargeListInfo, error } = useSelector(
      state => state.rechargeList,
  );

  const isSticky =
      rechargeListInfo?.datas?.length > 0 ? { height: '80vh' } : {};

  return (
      <Card className={style.card}>
        <div className="table-responsive" style={isSticky}>
          <Table hover bordered striped className="mb-5">
            <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
            <tr>
              <th>
                <div>補收單號 </div>
                <div>補收項目</div>
                <div>補收金額</div>
              </th>
              <th>
                <div>補收單狀態</div>
                <div>成立時間</div>
                <div>狀態時間</div>
              </th>
              <th>
                <div>退貨單狀態</div>
                <div>退貨成立時間</div>
                <div>退貨狀態時間</div>
                {/* <div>是否代開立發票</div> */}
              </th>
              <th>
                <div>主單編號</div>
                <div>退貨單號</div>
                <div>訂單編號</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>商品單價</div>
                <div>折扣金額</div>
                <div>銷售金額(折扣後)</div>
              </th>
              <th>
                <div>有無商品贈品</div>
                <div>有無活動贈品</div>
                <div>折扣活動總折扣金額</div>
                <div>折價券總折扣金額</div>
                <div>贈品券活動序號</div>
              </th>
              <th>
                <div>會員編號</div>
                <div>會員等級</div>
                <div>退貨聯絡人</div>
                <div>退貨聯絡人電話</div>
              </th>
              <th>
                <div>退貨國家</div>
                <div>退貨郵遞區號</div>
                <div>退貨城市</div>
                <div>退貨鄉鎮市區</div>
                <div>退貨地址</div>
              </th>
            </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
                <tbody>
                {rechargeListInfo?.datas?.length > 0 ? (
                    rechargeListInfo?.datas?.map(i => (
                        <tr className="text-nowrap" key={JSON.stringify(i)}>
                          <td>
                            <div>{i.rechargeNumber || '--'}</div>
                            <div>{i.items || '--'}</div>
                            <div className="price text-dark fw-normal">
                              {i.amount?.toLocaleString('en-US')}
                            </div>
                          </td>
                          <td>
                            <div>{i.rechargeStatusName || '--'}</div>
                            <div>{i.rechargeCreateTime || '--'}</div>
                            <div>{i.rechargeStatusTime || '--'}</div>
                          </td>
                          <td>
                            <div>{i.returnStatusName || '--'}</div>
                            <div>{i.returnCreateTime || '--'}</div>
                            <div>{i.returnStatusTime || '--'}</div>
                            {/* TODO 是否開立發票 */}
                          </td>
                          <td>
                            <div>{i.orderNumber || '--'}</div>
                            <div>{i.returnNumber || '--'}</div>
                            <div className={style.text_dark_info}>
                              {i.orderItemNumber || '--'}
                            </div>
                          </td>
                          <td>
                            {/* 商品名稱 */}
                            <div>{i.productname}</div>
                            {/* 商品選項 */}
                            <div>{`${i.color || '--'}/${i.size || '--'}`}</div>
                            {/* 商品料號 */}
                            <div
                                style={{
                                  color: '#0077ff',
                                  cursor: 'pointer',
                                  fontSize: '16px',
                                }}
                                onClick={() => {
                                  window.open(
                                      `/#/ProductControl?page=1&keyword=${i.productname}`,
                                      '_blank',
                                  );
                                }}
                            >
                              {i.productcode || '--'}
                            </div>
                            {/* 商品屬性 */}
                            <div>{Number(i.producrType) === 4 ? '贈品' : '商品'}</div>
                          </td>
                          <td className="text-end">
                            <div>{i.quantity || '--'}</div>
                            <div className="price text-black fw-normal">
                              {i.unitPrice.toLocaleString('en-US')}
                            </div>
                            <div className="d-flex justify-content-end">
                              -
                              <div className="price text-black fw-normal">
                                {(i.discountAmount || '0')?.toLocaleString('en-US')}
                              </div>
                            </div>
                            <div className="price text-black fw-normal">
                              {(i.finalPrice || '0')?.toLocaleString('en-US')}
                            </div>
                          </td>
                          <td className="text-end">
                            {/* 有無商品贈品 */}
                            <div>--</div>
                            {/* 有無活動贈品 */}
                            <div>--</div>
                            {/* 折扣活動總折扣金額 */}
                            <div
                                className="d-flex justify-content-end"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setCurrentDiscountInfo(i.orderItemId);
                                  setIsDiscountActivityModalOpen();
                                }}
                            >
                              -
                              <div
                                  className={`price fw-normal ${style.text_dark_info}`}
                              >
                                {(i.activityDiscountAmount || '0')?.toLocaleString(
                                    'en-US',
                                )}
                              </div>
                            </div>
                            {/* 折價券總折扣金額 */}
                            <div
                                className="d-flex justify-content-end"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setCurrentDiscountInfo(i.orderItemId);
                                  setIsDiscountModalOpen();
                                }}
                            >
                              -
                              <div
                                  className={`price fw-normal ${style.text_dark_info}`}
                              >
                                {(i.couponDiscountPrice || '0')?.toLocaleString(
                                    'en-US',
                                ) || 0}
                              </div>
                            </div>
                            {/* 贈品券活動序號 */}
                            <div>--</div>
                          </td>
                          <td>
                            <div className="text-nowrap">{i.memberPhone || '--'}</div>
                            <div>{i.memberLevel || '--'}</div>
                            <div>{i.returnName || '--'}</div>
                            <div>{i.returnPhone || '--'}</div>
                          </td>
                          <td>
                            <div>{i.returnCountryName || '--'}</div>
                            <div>{i.returnZipcode || '--'}</div>
                            <div>{i.returnCityName || '--'}</div>
                            <div>{i.returnTownName || '--'}</div>
                            <div>{i.returnAddress || '--'}</div>
                          </td>
                        </tr>
                    ))
                ) : (
                    <TableHasNoData />
                )}
                </tbody>
            )}
          </Table>
        </div>
        {rechargeListInfo?.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                  defaultCurrent={rechargeListInfo?.current_page}
                  defaultPageSize={rechargeListInfo?.page_size}
                  total={rechargeListInfo?.total_count}
                  onChange={onChangePage}
              />
            </div>
        )}
      </Card>
  );
};
const OrderReturnRepayList = () => {
  const dispatch = useDispatch();
  const { loading, rechargeListInfo, error } = useSelector(
      state => state.rechargeList,
  );
  const { orderOptionsInfo } = useSelector(state => state.orderOptions);

  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
      useToggle();
  const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null);

  const query = useQuery();
  const { location } = history;

  const [currentValues, setCurrentValues] = useState({});

  useEffect(() => {
    dispatch(getOrderOptions());
  }, []);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      endTime: null,
      orderNumber: '',
      orderNumberType: '主單編號',
      page: 1,
      page_size: 10,
      rechargeStatus: 0,
      returnKeyword: '',
      startTime: null,
      timeType: '訂購時間',
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
            ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
            ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(
            `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
        );
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getRechargeList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime)
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        keyword: `${query.value.keyword}`,
        orderNumber: `${query.value.orderNumber}`,
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime)
            : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      dispatch(getRechargeList(body));
      setValues(body);
    } else {
      handleReset();
    }
  }, [location.search]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  useEffect(() => {
    if (rechargeListInfo) {
      if (JSON.stringify(rechargeListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: rechargeListInfo });
      }
    }
  }, [rechargeListInfo]);

  return (
      <Layout
          pageTitle="補收單查詢"
          items={[
            { isActive: false, page_name: '退貨訂單管理' },
            { isActive: true, page_name: '補收單查詢' },
          ]}
      >
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form>
              <div className="d-flex flex-wrap w-100">
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label text-nowrap mr-2">
                    關鍵字
                  </label>
                  <input
                      type="text"
                      className={`form-control ${style['form-keyword']}`}
                      name="returnKeyword"
                      placeholder="會員電話、退貨聯絡人、退貨聯絡人電話"
                      onChange={handleChange}
                      value={values.returnKeyword}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">編號</label>
                  <Input
                      type="select"
                      className={style['form-select-short']}
                      name="orderNumberType"
                      onChange={handleChange}
                      value={values.orderNumberType}
                      style={{ margin: 0 }}
                  >
                    <option value="主單編號">主單編號</option>
                    <option value="訂單編號">訂單編號</option>
                    <option value="退貨單號">退貨單號</option>
                    <option value="補收單號">補收單號</option>
                  </Input>
                  <Input
                      type="text"
                      className={style['form-input-order-number']}
                      name="orderNumber"
                      onChange={handleChange}
                      value={values.orderNumber}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label">補收單狀態</label>
                  <SearchSelect
                      className={style['form-select-short']}
                      options={orderOptionsInfo?.rechargeStatus}
                      emptyVal={0}
                      name="rechargeStatus"
                      setFieldValue={setFieldValue}
                      values={values}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">
                    日期區間
                  </label>
                  <Input
                      type="select"
                      className={style['form-select-short']}
                      name="timeType"
                      onChange={handleChange}
                      value={values.timeType}
                      style={{ margin: 0 }}
                  >
                    <option value="訂購時間">訂購時間</option>
                  </Input>
                  <DatePicker
                      className="form-control"
                      popperClassName={style['form-control-date-picker']}
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.startTime
                            ? moment(values.startTime).toDate()
                            : moment().subtract(7, 'day').toDate()
                      }
                      name="startTime"
                      onChange={date => {
                        setFieldValue('startTime', date);
                      }}
                      selectsStart
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      popperClassName={style['form-control-date-picker']}
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.endTime
                            ? moment(values.endTime).toDate()
                            : moment().toDate()
                      }
                      name="endTime"
                      onChange={date => {
                        setFieldValue('endTime', date);
                      }}
                      selectsEnd
                      minDate={values.startTime}
                  />
                </div>
              </div>
            </form>
            <div className={style.button_position}>
              <Button
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    handleReset();
                  }}
              >
                取消
              </Button>
              <Button
                  type="button"
                  className={style.modal_button_submit}
                  onClick={() => {
                    setFieldValue('page', 1);
                    handleSubmit();
                  }}
              >
                查詢
              </Button>
            </div>
          </div>
        </Card>
        <TableList
            onChangePage={handleChangePage}
            setIsDiscountActivityModalOpen={setIsDiscountActivityModalOpen}
            setIsDiscountModalOpen={setIsDiscountModalOpen}
            setCurrentDiscountInfo={setCurrentDiscountInfo}
        />
        {isDiscountModalOpen && (
            <DiscountDetailModal
                isOpen={isDiscountModalOpen}
                toggle={setIsDiscountModalOpen}
                id={currentDiscountInfo}
            />
        )}
        {isDiscountActivityModalOpen && (
            <DiscountActivityDetailModal
                isOpen={isDiscountActivityModalOpen}
                toggle={setIsDiscountActivityModalOpen}
                id={currentDiscountInfo}
            />
        )}
      </Layout>
  );
};
export default OrderReturnRepayList;
