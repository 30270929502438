import Layout from 'components/Layout';
import OrderRemarkModal from 'components/OrderRemarkModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Input, Table } from 'reactstrap';
import { getOrderList } from 'redux/actions/data/orderActions';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const TableList = ({ onChangePage, onRefresh }) => {
  const { loading, orderListInfo, error } = useSelector(
    state => state.orderList,
  );
  const isSticky = orderListInfo?.datas?.length > 0 ? { height: '80vh' } : {};

  const [orderInfo, setOrderInfo] = useState(null);
  const [remarkOpen, setRemarkOpen] = useState(false);
  const toggleRemark = () => {
    setRemarkOpen(!remarkOpen);
  };

  return (
    <Card className={style.card}>
      <div className="table-responsive mb-5" style={isSticky}>
        <Table hover bordered striped>
          <thead
            className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
          >
            <tr>
              <th>訂單編號</th>
              <th>
                <div>轉單時間</div>
                <div>配送方式</div>
              </th>
              <th>
                <div>取消原因</div>
                <div>訂單取消日</div>
              </th>
              <th>
                <div>付款方式</div>
                <div>訂單確認日期</div>
                <div>預計出貨日</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>商品單價</div>
                <div>折扣金額</div>
                <div>銷售金額(折扣後)</div>
              </th>
              <th>
                <div>有無商品贈品</div>
                <div>有無活動贈品</div>
                <div>是否為加購價</div>
                <div>折扣活動總折扣金額</div>
                <div>折價券總折扣金額</div>
              </th>
              <th>
                <div>會員編號</div>
                <div>會員等級</div>
                <div>收件人</div>
                <div>收件人電話</div>
              </th>
              <th>出貨狀態</th>
              <th>
                <div>地址</div>
                <div>門市店號</div>
                <div>門市名稱</div>
              </th>
              <th>商店訂單備註</th>
              <th>消費者訂單備註</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {orderListInfo?.datas?.length > 0 ? (
                orderListInfo?.datas?.map(i => (
                  <React.Fragment key={JSON.stringify(i)}>
                    <tr className="text-nowrap bg">
                      <td className="price text-black">
                        總金額：{(i.totalAmount || '0').toLocaleString('en-US')}
                      </td>
                      <td>主單編號：{i.orderNumber}</td>
                      <td colSpan="2">
                        <div>
                          運費：
                          <span className="price text-black">
                            {i.deliveryCharge?.toLocaleString('en-US')}
                          </span>
                        </div>
                        {i.deliveryChargeReturnNumber && (
                          <span>
                            （退運費申請 -
                            <span className={style.text_dark_info}>
                              {i.deliveryChargeReturnNumber}
                            </span>
                            )
                          </span>
                        )}
                      </td>
                      <td colSpan={8} className="text-end">
                        <FaChevronDown id={`orderId${i.id}`} />
                      </td>
                    </tr>
                    {i.orderItems.map((j, jIndex) => (
                      <tr key={jIndex}>
                        <td>{j.orderItemNumber}</td>
                        <td>
                          <div>{i.orderTime || '--'}</div>
                          <div>{j.logisticsWayName || '--'}</div>
                        </td>
                        <td>
                          <div>{j.cancelRemark || '--'}</div>
                          <div>{j.cancelTime || '--'}</div>
                        </td>
                        <td>
                          <div>{i.payWayName || '--'}</div>
                          <div>{j.checkOrderTime || '--'}</div>
                          <div>{j.expectedDeliveryDate || '--'}</div>
                        </td>
                        <td>
                          {/* 商品名稱 */}
                          <div>{j.productname || '--'}</div>
                          {/* 商品選項 */}
                          <div>
                            {j.color || '--'}/{j.size || '--'}
                          </div>
                          {/* 商品料號 */}
                          <div
                            style={{
                              color: '#0077ff',
                              cursor: 'pointer',
                              fontSize: '16px',
                            }}
                            onClick={() => {
                              sessionStorage.clear();
                              sessionStorage.setItem('keyword', j.productname);
                              if (Number(j.productType) === 4) {
                                window.open(
                                  `/#/GiftManagement?page=1&keyword=${
                                    j.productname || j.freebieCode
                                  }`,
                                  '_blank',
                                );
                              } else if (Number(j.productType) === 1) {
                                window.open(
                                  `/#/ProductControl?page=1&keyword=${
                                    j.productname || j.productcode
                                  }`,
                                  '_blank',
                                );
                              }
                            }}
                          >
                            {Number(j.productType) === 4
                              ? j.freebieCode || '--'
                              : j.productcode || '--'}
                          </div>
                          {/* 商品屬性 */}
                          <div>
                            {Number(j.productType) === 4
                              ? j.freebieType || '--'
                              : '商品'}
                          </div>
                        </td>
                        <td className="text-end">
                          {/* 數量 */}
                          <div>{j.quantity || '--'}</div>
                          {/* 商品單價 */}
                          <div className="price text-black fw-normal">
                            {j.unitPrice.toLocaleString('en-US')}
                          </div>
                          {/* 折扣金額 */}
                          <div
                            className="d-flex justify-content-end text-primary"
                            role="button"
                            onClick={() => {
                              modal.showBillInfo({
                                ...j,
                                orderNumber: i.orderNumber,
                              });
                            }}
                          >
                            -
                            <div className="price text-primary fw-normal">
                              {j?.discountAmountUnit?.toLocaleString('en-US') ||
                                0}
                            </div>
                          </div>
                          {/* 銷售金額(折扣後) */}
                          <div className="price text-black fw-normal">
                            {(j.finalPriceUnit || 0).toLocaleString('en-US')}
                          </div>
                        </td>
                        <td className="text-end">
                          {/* 有無商品贈品 */}
                          <div>--</div>
                          {/* 有無活動贈品 */}
                          <div>--</div>
                          {/* 折扣活動總折扣金額 */}
                          <div className="d-flex justify-content-end">
                            -
                            <div className="price text-black fw-normal">
                              {(
                                j.activityDiscountAmountUnit || '0'
                              )?.toLocaleString('en-US')}
                            </div>
                          </div>
                          {/* 折價券總折扣金額 */}
                          <div className="d-flex justify-content-end">
                            -
                            <div className="price text-black fw-normal">
                              {(
                                j.couponDiscountAmountUnit || '0'
                              )?.toLocaleString('en-US')}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>{j.memberPhone}</div>
                          <div>{j.memberLevel}</div>
                          <div>{j.name}</div>
                          <div>{j.phone}</div>
                        </td>
                        <td>{j.shippingStatusName}</td>
                        <td>
                          {(j.logisticsWay === 1 || j.logisticsWay === 2) && (
                            <div>{j.cvsAddress || '--'}</div>
                          )}
                          {(j.logisticsWay === 1 || j.logisticsWay === 2) && (
                            <div>{j.cvsPaymentNo || '--'}</div>
                          )}
                          {(j.logisticsWay === 1 || j.logisticsWay === 2) && (
                            <div>{j.cvsStoreName || '--'}</div>
                          )}
                          {j.logisticsWay === 3 && (
                            <div>
                              {`${j.countryName}${j.cityName}${j.townName}${j.address}`}
                            </div>
                          )}
                          {j.logisticsWay === 4 && (
                            <div>{j.orderStoreAddr || '--'}</div>
                          )}
                          {j.logisticsWay === 4 && (
                            <div>{j.orderStore || '--'}</div>
                          )}
                          {j.logisticsWay === 4 && (
                            <div>{j.orderStoreName || '--'}</div>
                          )}
                        </td>
                        <td>
                          <div>{j.orderRemark || '--'}</div>
                          <div
                            style={{
                              color: '#0077ff',
                              cursor: 'pointer',
                              fontSize: '14px',
                            }}
                            onClick={() => {
                              setOrderInfo({
                                requestBody: {
                                  orderItemId: j.id,
                                  orderRemark: j.orderRemark,
                                },
                                主單編號: i.orderNumber,
                                商品名稱: j.productname,
                                訂單編號: j.orderItemNumber,
                              });
                              toggleRemark();
                            }}
                          >
                            編輯備註
                          </div>
                        </td>
                        <td>{j.remark || '--'}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))
              ) : (
                <TableHasNoData />
              )}
              {error && <TableHasNoData />}
            </tbody>
          )}
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <RcPagination
          current={orderListInfo?.current_page || 1}
          pageSize={orderListInfo?.page_size || 10}
          total={orderListInfo?.total_count || 0}
          onChange={onChangePage}
        />
      </div>
      {orderInfo && (
        <OrderRemarkModal
          isOpen={remarkOpen}
          toggle={toggleRemark}
          info={orderInfo}
          callBack={onRefresh}
        />
      )}
    </Card>
  );
};
const CancelOrderList = () => {
  const dispatch = useDispatch();
  const handleSearch = params => {
    dispatch(getOrderList(params));
  };
  const [pageIsChanged, setPageIsChanged] = useState(false);

  useEffect(() => {
    handleSearch({
      endTime: moment().toDate(),
      orderStatus: 3,
      page: 1,
      page_size: 10,
      startTime: moment().subtract(7, 'day').toDate(),
      timeType: '取消日期',
    });
  }, []);
  const { values, handleChange, handleSubmit, setFieldValue, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        endTime: null,
        keyword: '',
        orderNumber: '',
        orderNumberType: '主單編號',
        orderStatus: 3,
        page: 1,
        page_size: 10,
        startTime: null,
        timeType: '取消日期',
      },
      async onSubmit(formModel) {
        const params = {
          ...formModel,
          endTime: formModel.endTime ?? moment().toDate(),
          page: pageIsChanged ? formModel.page : 1,
          startTime:
            formModel.startTime ?? moment().subtract(7, 'day').toDate(),
        };
        await dispatch(getOrderList(params));
        setPageIsChanged(false);
      },
    });

  const handleChangePage = n => {
    setFieldValue('page', n);
    setPageIsChanged(true);
    handleSubmit();
  };

  return (
    <Layout
      pageTitle="取消訂單查詢"
      items={[
        { isActive: false, page_name: '取消訂單管理' },
        { isActive: true, page_name: '取消訂單查詢' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>
            本頁面只出現主單狀態為【已取消】之訂單
          </span>
        </div>
      </Alert>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className="d-flex flex-wrap w-100">
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem', width: '100%' }}
              >
                <label className="col-form-label text-nowrap mr-2">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-keyword']}`}
                  name="keyword"
                  placeholder="會員電話、收件人、收件人電話"
                  onChange={handleChange}
                  value={values.keyword}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">編號</label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  name="orderNumberType"
                  onChange={handleChange}
                  value={values.orderNumberType}
                  style={{ margin: 0 }}
                >
                  <option value="主單編號">主單編號</option>
                  <option value="訂單編號">訂單編號</option>
                </Input>
                <Input
                  type="text"
                  className={style['form-input-order-number']}
                  name="orderNumber"
                  onChange={handleChange}
                  value={values.orderNumber}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">
                  日期區間
                </label>
                <Input
                  type="select"
                  className={style['form-select']}
                  name="timeType"
                  onChange={handleChange}
                  value={values.timeType}
                  style={{ margin: 0 }}
                >
                  <option value="取消日期">取消日期</option>
                  <option value="訂購時間">訂購時間</option>
                </Input>
                <DatePicker
                  className={'form-control'}
                  popperClassName={style['form-control-date-picker']}
                  dateFormat="yyyy/MM/dd p"
                  showTimeSelect
                  selected={
                    values.startTime ?? moment().subtract(7, 'day').toDate()
                  }
                  name="startTime"
                  onChange={date => {
                    setFieldValue('startTime', date);
                  }}
                  selectsStart
                />
                <span style={{ margin: '1rem' }}>~</span>
                <DatePicker
                  className="form-control"
                  popperClassName={style['form-control-date-picker']}
                  dateFormat="yyyy/MM/dd p"
                  showTimeSelect
                  selected={values.endTime ?? new Date()}
                  name="endTime"
                  onChange={date => {
                    setFieldValue('endTime', date);
                  }}
                  selectsEnd
                  minDate={values.startTime}
                />
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
                handleSubmit(values);
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <TableList onChangePage={handleChangePage} onRefresh={handleSubmit} />
    </Layout>
  );
};
export default CancelOrderList;
