import BillInfo from 'components/BillInfo';
import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import OrderRemarkModal from 'components/OrderRemarkModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input, Table } from 'reactstrap';
import { getOrderList } from 'redux/actions/data/orderActions';
import { createHiddenInput } from 'utils';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import request from 'utils/useAxios';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const TableList = ({
                     onChangePage,
                     values: searchParams,
                     onToggleDiscountModal,
                     onToggleOrderRemarkModal,
                     onToggleBillInfoModal,
                     setOrderInfo,
                     setBillInfo,
                   }) => {
  const { loading, orderListInfo, error } = useSelector(
    state => state.orderList,
  );

  const { values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      list: [],
    },
  });

  const handleToDetail = id => {
    history.push(`/OrderDetail/${id}`);
  };
  const handlePrintDetail = () => {
    const params = new URLSearchParams();
    values.list.forEach(i => {
      params.append('orderNumber', i.id);
    });
    const win = window.open(
      `/#/OrderPrintDetail?${params.toString()}`,
      '_blank',
    );
    win.focus();
  };
  const handlePrintOrderInfo = async data => {
    const { code, message, result } = await request({
      data: data,
      method: 'post',
      url: `/Website/CreateLogistics/${
        searchParams.logisticsWay === '1'
          ? 'PrintUniMartC2COrderInfo'
          : 'PrintFAMIC2COrderInfo'
      }`,
    });
    if (code !== 200) {
      MessageUtil.alertWanring('發生錯誤！', message);
      return;
    }
    const form = document.createElement('form');
    form.method = 'POST';
    form.target = '_blank';
    form.action = `${process.env.REACT_APP_EC_PAY}/Express/${
      searchParams.logisticsWay === '1'
        ? 'PrintUniMartC2COrderInfo'
        : 'PrintFAMIC2COrderInfo'
    }`;
    createHiddenInput(form, 'AllPayLogisticsID', result.allPayLogisticsID);
    createHiddenInput(form, 'CheckMacValue', result.checkMacValue);
    createHiddenInput(form, 'CVSPaymentNo', result.cvsPaymentNo);
    createHiddenInput(form, 'MerchantID', result.merchantID);
    createHiddenInput(form, 'PlatformID', result.platformID);
    searchParams.logisticsWay === '1' &&
    createHiddenInput(form, 'CVSValidationNo', result.cvsValidationNo);
    document.body.appendChild(form);
    form.submit();
  };

  useEffect(() => {
    CommonUtil.consoleLog({ anno: 'response', data: orderListInfo });
  }, [orderListInfo]);

  return (
    <Card className={style.card}>
      <div className="mb-3">
        <Button
          className="me-2"
          disabled={values.list.length <= 0}
          onClick={handlePrintDetail}
        >
          列印出貨明細單
        </Button>
        {(searchParams.logisticsWay === '1' ||
          searchParams.logisticsWay === '2') && (
          <Button
            disabled={values.list.length <= 0}
            onClick={() =>
              handlePrintOrderInfo(values.list.map(i => i.orderNumber))
            }
          >
            列印標籤
          </Button>
        )}
      </div>
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>
              <input
                className={style.maintain_list_checkbox}
                type="checkbox"
                checked={
                  values.list.length > 0 &&
                  values.list.length === orderListInfo?.datas?.length
                }
                name="all"
                onChange={e => {
                  const val = e.target.checked;
                  setFieldValue(
                    'list',
                    val
                      ? orderListInfo?.datas?.map(i => ({
                        id: i.id,
                        orderNumber: i.orderNumber,
                      }))
                      : [],
                  );
                }}
              />{' '}
              全選
            </th>
            <th>
              <div>主單編號</div>
              <div>訂單編號</div>
            </th>
            <th>配送資訊</th>
            <th>
              <div>轉單時間</div>
              <div>配號時間</div>
              <div>寄件起始日</div>
              <div>寄件截止日</div>
            </th>
            <th>
              <div>出貨單狀態</div>
              <div>交期</div>
              <div>預計出貨日</div>
            </th>
            <th>
              <div>商品名稱</div>
              <div>商品選項</div>
              <div>商品料號</div>
              <div>商品屬性</div>
            </th>
            <th>
              <div>數量</div>
              <div>銷售金額</div>
            </th>
            <th>促銷/贈品</th>
            <th>收件人資訊</th>
            <th>商店訂單備註</th>
            <th>訂購備註</th>
            <th>訂單狀態</th>
          </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
            {orderListInfo?.datas?.length > 0 ? (
              orderListInfo?.datas?.map((i, index) => (
                <React.Fragment key={i.id}>
                  <tr
                    className="text-nowrap"
                    style={{ backgroundColor: '#BEC0D8' }}
                  >
                    <td>
                      <input
                        className={style.maintain_list_checkbox}
                        type="checkbox"
                        onChange={e => {
                          const val = e.target.checked;
                          setFieldValue(
                            'list',
                            val
                              ? [
                                ...values.list,
                                { id: i.id, orderNumber: i.orderNumber },
                              ]
                              : values.list.filter(
                                j => j.orderNumber !== i.orderNumber,
                              ),
                          );
                        }}
                        checked={values.list.some(
                          j => j.orderNumber === i.orderNumber,
                        )}
                      />
                    </td>
                    <td colSpan="2">
                      <div>配送編號：{i.cvsPaymentNo || '--'}</div>
                      {/* <div>訂購時間：{i.orderTime}</div> */}
                    </td>
                    <td colSpan={9}>
                      <div className="d-flex align-items-center">
                        <Button
                          color="success"
                          outline
                          className="bg-white text-success me-5"
                          disabled={
                            !values.list.some(
                              k => k.orderNumber === i.orderNumber,
                            )
                          }
                          onClick={() =>
                            handlePrintOrderInfo([i.orderNumber])
                          }
                        >
                          列印標籤
                        </Button>
                        <div className="me-2">
                          門市列印專用代碼：{i.shipmentNo?? '--' }
                        </div>
                        / 代收金額：
                        <div className="price text-black fw-normal me-auto">
                          {i.actualAmount.toLocaleString('en-US')}
                        </div>
                        <FaChevronDown id={`orderId${i.id}`} />
                      </div>
                    </td>
                  </tr>
                  {i.orderItems.map((j, jIndex) => (
                    <tr key={jIndex}>
                      <td></td>
                      <td>
                        {/* 主單編號 */}
                        <div>{i.orderNumber}</div>
                        {/* 訂單編號 */}
                        <div>{j.orderItemNumber}</div>
                      </td>
                      <td>
                        {/* 配送資訊 */}
                        <div>{j.logisticsWayName}</div>
                        <div>{j.homeDeliveryCName}</div>
                        <div>{j.homeDeliveryNumber}</div>
                        <div>主單運費：</div>
                        <div className="price text-black fw-normal">
                          {i.deliveryCharge}
                        </div>
                      </td>
                      <td>
                        {/* 轉單時間 */}
                        <div>{i.orderTime || '--'}</div>
                        {/* 配號時間 */}
                        <div>{i.checkOrderTime || '--'}</div>
                        {/* 寄件起始日 */}
                        <div>{i.cstoreSendStartTime || '--'}</div>
                        {/* 寄件截止日 */}
                        <div>{i.cstoreSendEndTime || '--'}</div>
                      </td>
                      <td>
                        {/* 出貨單狀態 */}
                        <div>{j.shippingStatusName}</div>
                        {/* 交期 */}
                        <div>
                          {Number(j.deliveryType) === 1 ? '一般' : '預購'}
                        </div>
                        {/* 預計出貨日 */}
                        <div>{j.expectedDeliveryDate}</div>
                      </td>
                      <td>
                        {/* 商品名稱 */}
                        <div>{j.productname || '--'}</div>
                        {/* 商品選項 */}
                        <div>
                          {j.color || '--'}/{j.size || '--'}
                        </div>
                        {/* 商品料號 */}
                        <div
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            fontSize: '16px',
                          }}
                          onClick={() => {
                            sessionStorage.clear();
                            sessionStorage.setItem('keyword', j.productname);
                            if (Number(j.productType) === 4) {
                              window.open(
                                `/#/GiftManagement?page=1&keyword=${
                                  j.productname || j.freebieCode
                                }`,
                                '_blank',
                              );
                            } else if (Number(j.productType) === 1) {
                              window.open(
                                `/#/ProductControl?page=1&keyword=${
                                  j.productname || j.productcode
                                }`,
                                '_blank',
                              );
                            }
                          }}
                        >
                          {Number(j.productType) === 4
                            ? j.freebieCode || '--'
                            : j.productcode || '--'}
                        </div>
                        {/* 商品屬性 */}
                        <div>
                          {Number(j.productType) === 4
                            ? j.freebieType || '--'
                            : '商品'}
                        </div>
                      </td>
                      <td className="text-end">
                        {/* 數量 */}
                        <div>{j.quantity}</div>
                        {/* 銷售金額 */}
                        <div className="price text-black fw-normal">
                          {(j.finalPriceUnit || '0').toLocaleString('en-US')}
                        </div>
                        <div
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textAlign: 'center',
                          }}
                          onClick={() => {
                            onToggleBillInfoModal();
                            setBillInfo({
                              amount: j.finalPrice,
                              price: {
                                商品單價: j.unitPrice,
                                折扣金額: j.discountAmountUnit,
                              },
                              productInfo: {
                                主單編號: i.orderNumber,
                                商品名稱: j.productname,
                                數量: j.quantity,
                                訂單編號: j.orderItemNumber,
                              },
                            });
                          }}
                        >
                          查看明細
                        </div>
                      </td>
                      <td className="text-end">
                        {/* 促銷/贈品 */}
                        <div
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            fontSize: '14px',
                          }}
                          onClick={() => {
                            onToggleDiscountModal();
                          }}
                        >
                          折扣活動明細
                        </div>
                      </td>
                      <td>
                        {/* 收件人資訊 */}
                        <div>{j.name}</div>
                        <div>{j.phone}</div>
                        {
                          // 宅配 : 收件人地址, 超商 : 門市名稱 & 地址
                          Number(j.logisticsWay) === 3 ? (
                            <div>
                              {`${j.cityName || '--'}${j.townName || '--'}${
                                j.address || '--'
                              }`}
                            </div>
                          ) : (
                            <>
                              <div>{j.cvsStoreName}</div>
                              <div>{j.cvsAddress}</div>
                            </>
                          )
                        }
                      </td>
                      <td className="text-center">
                        {/* 商店訂單備註 */}
                        <div>{j.orderRemark || '--'}</div>
                        <div
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            fontSize: '14px',
                          }}
                          onClick={() => {
                            setOrderInfo({
                              requestBody: {
                                orderItemId: j.id,
                                orderRemark: j.orderRemark,
                              },
                              主單編號: i.orderNumber,
                              商品名稱: j.productname,
                              訂單編號: j.orderItemNumber,
                            });
                            onToggleOrderRemarkModal();
                          }}
                        >
                          編輯備註
                        </div>
                      </td>
                      <td className="text-center">
                        {/* 訂購備註 */}
                        <div>{j.remark || '--'}</div>
                      </td>
                      <td className="text-center">
                        {/* 訂單狀態 */}
                        <div>{j.orderItemStatusName}</div>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <TableHasNoData />
            )}
            </tbody>
          )}
        </Table>
        {/* {loading && (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        )} */}
      </div>
      {orderListInfo?.total_count > 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={orderListInfo?.current_page}
            defaultPageSize={orderListInfo?.page_size}
            total={orderListInfo?.total_count}
            onChange={onChangePage}
          />
        </div>
      )}
    </Card>
  );
};
const ShippingPrintInfo = () => {
  const dispatch = useDispatch();
  const [orderInfo, setOrderInfo] = useState(null);
  const [billInfo, setBillInfo] = useState(null);
  const [isOrderRemarkModalOpen, setIsOrderRemarkModalOpen] = useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isBillInfoModalOpen, setIsBillInfoModalOpen] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const { values, handleChange, handleSubmit, setFieldValue, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        endTime: null,
        logisticsWay: 3,
        orderNumber: '',
        orderNumberType: '主單編號',
        page: 1,
        page_size: 10,
        startTime: null,
        timeType: '已出貨時間',
      },
      async onSubmit(formModel) {
        setShowTable(true);
        const params = {
          ...formModel,
          endTime: formModel.endTime
            ? new Date(formModel.endTime).toLocaleString('zh-TW', {
              dateStyle: 'medium',
              hourCycle: 'h23',
              timeStyle: 'short',
            })
            : new Date(moment().toDate()).toLocaleString('zh-TW', {
              dateStyle: 'medium',
              hourCycle: 'h23',
              timeStyle: 'short',
            }),
          logisticsWay: +formModel.logisticsWay,
          startTime: formModel.startTime
            ? new Date(formModel.startTime).toLocaleString('zh-TW', {
              dateStyle: 'medium',
              hourCycle: 'h23',
              timeStyle: 'short',
            })
            : new Date(moment().subtract(7, 'day').toDate()).toLocaleString(
              'zh-TW',
              { dateStyle: 'medium', hourCycle: 'h23', timeStyle: 'short' },
            ),
        };
        CommonUtil.consoleLog({ anno: 'request body', data: params });
        await dispatch(getOrderList(params));
      },
    });

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const toggleDiscountModal = () => {
    setIsDiscountModalOpen(!isDiscountModalOpen);
  };

  const toggleOrderRemarkModal = () => {
    setIsOrderRemarkModalOpen(!isOrderRemarkModalOpen);
  };

  const toggleBillInfoModal = () => {
    setIsBillInfoModalOpen(!isBillInfoModalOpen);
  };

  return (
    <Layout
      pageTitle="補印標籤 及 出貨明細"
      items={[{ isActive: true, page_name: '補印標籤 及 出貨明細' }]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 requird">配送方式</label>
                <div className={style.show_flex}>
                  <label className="form-label">
                    <input
                      className="mr-2"
                      name="logisticsWay"
                      type="radio"
                      value={3}
                      onChange={handleChange}
                      defaultChecked={true}
                    />
                    宅配
                  </label>
                  <label className="form-label">
                    <input
                      className="mr-2"
                      name="logisticsWay"
                      type="radio"
                      value="2"
                      onChange={handleChange}
                    />
                    全家取貨
                  </label>
                  <label className="form-label">
                    <input
                      className="mr-2"
                      name="logisticsWay"
                      type="radio"
                      value="1"
                      onChange={handleChange}
                    />
                    7-11取貨
                  </label>
                  <label className="form-label">
                    <input
                      className="mr-2"
                      name="logisticsWay"
                      type="radio"
                      value="4"
                      onChange={handleChange}
                    />
                    門市取貨
                  </label>
                </div>
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label me-2 text-nowrap">編號</label>
                <Input
                  type="select"
                  className={style['form-select-medium']}
                  name="orderNumberType"
                  onChange={handleChange}
                  value={values.orderNumberType}
                  style={{ margin: 0 }}
                >
                  <option value="主單編號">主單編號</option>
                  <option value="物流編號">物流編號</option>
                </Input>
                <Input
                  type="text"
                  className={style['form-input-order-number']}
                  name="orderNumber"
                  onChange={handleChange}
                  value={values.orderNumber}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">
                  已出貨時間
                </label>
                <div className={style.show_flex}>
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy/MM/dd hh:mm"
                    showTimeSelect
                    timeIntervals={10}
                    selected={
                      values.startTime ?? moment().subtract(7, 'day').toDate()
                    }
                    name="startTime"
                    onChange={date => {
                      setFieldValue('startTime', date);
                    }}
                    onKeyDown={e => e.preventDefault()}
                    selectsStart
                  />
                </div>
                <span style={{ margin: '1rem' }}>~</span>
                <div className={style.show_flex}>
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy/MM/dd hh:mm"
                    showTimeSelect
                    timeIntervals={10}
                    selected={values.endTime ?? new Date()}
                    name="endTime"
                    onChange={date => {
                      setFieldValue('endTime', date);
                    }}
                    onKeyDown={e => e.preventDefault()}
                    selectsEnd
                    minDate={values.startTime}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
                handleSubmit(values);
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      {showTable && (
        <TableList
          onChangePage={handleChangePage}
          values={values}
          onToggleDiscountModal={toggleDiscountModal}
          onToggleOrderRemarkModal={toggleOrderRemarkModal}
          onToggleBillInfoModal={toggleBillInfoModal}
          setOrderInfo={setOrderInfo}
          setBillInfo={setBillInfo}
        />
      )}
      {isDiscountModalOpen && (
        <DiscountDetailModal
          isOpen={isDiscountModalOpen}
          toggle={toggleDiscountModal}
        />
      )}
      {isOrderRemarkModalOpen && orderInfo && (
        <OrderRemarkModal
          isOpen={isOrderRemarkModalOpen}
          toggle={toggleOrderRemarkModal}
          info={orderInfo}
          callBack={handleSubmit}
        />
      )}
      {isBillInfoModalOpen && (
        <BillInfo
          isOpen={isBillInfoModalOpen}
          toggle={toggleBillInfoModal}
          info={billInfo}
        />
      )}
    </Layout>
  );
};
export default ShippingPrintInfo;
