import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import OrderRemarkModal from 'components/OrderRemarkModal';
import SearchSelect from 'components/OrderSearchSelector';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, FormText, Input, Table } from 'reactstrap';
import { getOrderList, getOrderOptions } from 'redux/actions/data/orderActions';
import { exportOrdersInfo } from 'redux/actions/data/orderActions';
import { homeDeliveryShipping } from 'redux/actions/data/orderShippingActions';
import { HOME_DELIVERY_SHIPPING_RESET } from 'redux/constants/data/orderShippingConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';
import * as Yup from 'yup';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const ErrorText = ({ errors, touched, masterIndex, orderIndex, keyName }) => {
  const errorText =
      errors.list?.[masterIndex()]?.items?.[orderIndex]?.[keyName];
  const show =
      errorText && touched.list?.[masterIndex()]?.items?.[orderIndex]?.[keyName];
  return show ? (
      <FormText color="danger" className="d-block">
        {errorText}
      </FormText>
  ) : null;
};

const TableList = ({
                     onShipping,
                     onChangePage,
                     onToggleOrderRemarkModal,
                     setOrderInfo,
                     onExportOrders,
                     onToggleDiscountActivityModal,
                     setCurrentOrderItemId,
                   }) => {
  const { loading, orderListInfo } = useSelector(state => state.orderList);
  const { orderOptionsInfo } = useSelector(state => state.orderOptions);

  const { values, handleSubmit, setFieldValue, handleReset, errors, touched } =
      useFormik({
        enableReinitialize: true,
        initialValues: {
          all: false,
          list: [],
        },
        async onSubmit(formModel) {
          onShipping && (await onShipping(formModel, handleReset));
        },
        validationSchema: Yup.object().shape({
          list: Yup.array().of(
              Yup.object().shape({
                items: Yup.array().of(
                    Yup.object().shape({
                      homeDeliveryC: Yup.number().required().positive('必填'),
                      homeDeliveryNumber: Yup.string().required('必填'),
                    }),
                ),
              }),
          ),
        }),
      });
  const handleFindIndex = masterId => {
    return values.list.indexOf(values.list.find(i => i.masterId === masterId));
  };
  const handleToDetail = id => {
    history.push(`/OrderDetail/${id}`);
  };
  const handlePrintDetail = () => {
    const params = new URLSearchParams();
    values.list.forEach(i => {
      params.append('orderNumber', i.masterId);
    });
    const win = window.open(
        `/#/OrderPrintDetail?${params.toString()}`,
        '_blank',
    );
    win.focus();
  };

  const isSticky = orderListInfo?.datas?.length > 0 ? { height: '80vh' } : {};

  const handleToShippingPrintInfo = () => {
    history.push('/ShippingPrintInfo');
  };

  return (
      <Card className={style.card}>
        <div className="mb-2" style={{ position: 'relative' }}>
          <Button
              className="me-2 w150"
              color="success"
              onClick={handleSubmit}
              disabled={values.list.length <= 0}
              style={{ margin: '0.2rem' }}
          >
            出貨
          </Button>
          <Button
              className="me-2 w150"
              disabled={values.list.length <= 0}
              onClick={handlePrintDetail}
              style={{ margin: '0.2rem' }}
          >
            列印出貨明細單
          </Button>
          <Button
              className="me-2 w100"
              onClick={() => {
                onExportOrders();
              }}
          >
            匯出訂單
          </Button>
          <div
              className={style.show_flex}
              style={{ bottom: '0.5rem', position: 'absolute', right: '1rem' }}
          >
            <u
                className={style.text_dark_info}
                style={{ cursor: 'pointer', fontSize: '16px', textAlign: 'center' }}
                onClick={handleToShippingPrintInfo}
            >
              補印出貨明細
            </u>
          </div>
        </div>
        <div className="table-responsive mb-5" style={isSticky}>
          <Table hover bordered striped>
            <thead className={'thead-dark'}>
            <tr>
              <th colSpan="13">
                <div className={style.show_flex}>
                  <input
                      className={style.maintain_list_checkbox}
                      type="checkbox"
                      checked={
                          values.list.length > 0 &&
                          values.list.length === orderListInfo?.datas?.length
                      }
                      name="all"
                      onChange={e => {
                        const val = e.target.checked;
                        setFieldValue(
                            'list',
                            val
                                ? orderListInfo?.datas?.map(i => ({
                                  items: i.orderItems.map(j => ({
                                    homeDeliveryC: 0,
                                    homeDeliveryNumber: '',
                                    orderItemId: j.id,
                                  })),
                                  masterId: i.id,
                                }))
                                : [],
                        );
                      }}
                  />
                  <span style={{ marginLeft: '0.5rem', marginRight: '2.5rem' }}>
                    本頁全選
                  </span>
                  <div style={{ marginRight: '1rem' }}>配送商皆為</div>
                  <div style={{ marginRight: '2.5rem' }}>
                    <Input
                        type="select"
                        className={`${style['form-select-medium']} ${style['select-poper-z-index']}`}
                        style={{ maxWidth: '290px' }}
                        disabled={
                            values.list.length !== orderListInfo?.datas?.length
                        }
                        onChange={e => {
                          const value = e.target.value;
                          const newArr = values.list?.map(order => ({
                            ...order,
                            items: order.items.map(item => ({
                              ...item,
                              homeDeliveryC: value,
                            })),
                          }));
                          setFieldValue('list', newArr);
                        }}
                    >
                      <option value="0">選擇宅配商</option>
                      {orderOptionsInfo?.homeDeliveryStore?.map(store => (
                          <option key={store.code} value={store.code}>
                            {store.name}
                          </option>
                      ))}
                    </Input>
                  </div>
                  <div style={{ marginRight: '1rem' }}>配送編號皆為</div>
                  <div>
                    <Input
                        type="text"
                        className={style['form-input']}
                        style={{ maxWidth: '290px' }}
                        onChange={e => {
                          const value = e.target.value;
                          const newArr = values.list?.map(order => ({
                            ...order,
                            items: order.items.map(item => ({
                              ...item,
                              homeDeliveryNumber: value,
                            })),
                          }));
                          setFieldValue('list', newArr);
                        }}
                    ></Input>
                  </div>
                </div>
              </th>
            </tr>
            </thead>
            <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
            <tr>
              <th>選擇</th>
              <th>訂單編號</th>
              <th>配送資訊</th>
              <th>
                <div>配送商</div>
                <div>配送編號</div>
              </th>
              <th>
                <div>轉單時間</div>
                <div>訂單確認時間</div>
              </th>
              <th>
                <div>交期</div>
                <div>預計出貨日</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>銷售金額</div>
              </th>
              <th>促銷/贈品</th>
              <th>收件人資訊</th>
              <th>訂單狀態</th>
              <th>商店訂單備註</th>
              <th>訂購備註</th>
            </tr>
            </thead>
            {loading && <TableLoading />}

            {!loading && (
                <tbody>
                {orderListInfo?.datas?.length > 0 ? (
                    orderListInfo?.datas?.map(i => (
                        <React.Fragment key={JSON.stringify(i)}>
                          <tr className="text-nowrap bg">
                            <td>
                              <input
                                  className={style.maintain_list_checkbox}
                                  type="checkbox"
                                  onChange={e => {
                                    const val = e.target.checked;
                                    const params = {
                                      items: i.orderItems.map(j => ({
                                        homeDeliveryC: 0,
                                        homeDeliveryNumber: '',
                                        orderItemId: j.id,
                                      })),
                                      masterId: i.id,
                                    };
                                    val &&
                                    setFieldValue('list', [...values.list, params]);
                                    val ||
                                    setFieldValue(
                                        'list',
                                        values.list.filter(k => k.masterId !== i.id),
                                    );
                                  }}
                                  checked={Boolean(
                                      values.list.find(k => k.masterId === i.id),
                                  )}
                              />
                            </td>
                            <td colSpan="2">
                              <div>主單編號：{i.orderNumber}</div>
                            </td>
                            <td colSpan={10}>
                              <div className="d-flex align-items-center">
                                <Button
                                    color="success"
                                    outline
                                    className="bg-white text-success me-auto"
                                    onClick={() => {
                                      if (!values.list.find(j => j.masterId === i.id)) {
                                        setFieldValue(
                                            'list',
                                            [
                                              {
                                                items: i.orderItems.map(j => ({
                                                  homeDeliveryC: 0,
                                                  homeDeliveryNumber: '',
                                                  orderItemId: j.id,
                                                })),
                                                masterId: i.id,
                                              },
                                            ],
                                            true,
                                        ).then(() => {
                                          handleSubmit();
                                        });
                                      } else {
                                        setFieldValue(
                                            'list',
                                            values.list.filter(j => j.masterId === i.id),
                                        ).then(() => {
                                          handleSubmit();
                                        });
                                      }
                                    }}
                                >
                                  出貨
                                </Button>
                                <FaChevronDown id={`orderId${i.id}`} />
                              </div>
                            </td>
                          </tr>
                          {i.orderItems.map((j, jIndex) => (
                              <tr key={jIndex}>
                                <td></td>
                                <td>
                                  {/* 訂單編號 */}
                                  <div>{j.orderItemNumber}</div>
                                </td>
                                <td>
                                  {/* 配送資訊 */}
                                  <div>{j.logisticsWayName}</div>
                                  <div>{'主單運費'}</div>
                                  <div>{`NT$${i.deliveryCharge}`}</div>
                                </td>
                                <td>
                                  {/* 配送商 */}
                                  <Input
                                      className="w150 mb-1"
                                      type="select"
                                      onChange={val => {
                                        const value = val.target.value;
                                        const index = handleFindIndex(i.id);
                                        index > -1 &&
                                        setFieldValue(
                                            `list[${handleFindIndex(
                                                i.id,
                                            )}].items[${jIndex}].homeDeliveryC`,
                                            value,
                                        );
                                        index <= -1 &&
                                        setFieldValue('list', [
                                          ...values.list,
                                          {
                                            items: i.orderItems.map(j => ({
                                              homeDeliveryC: value,
                                              homeDeliveryNumber: '',
                                              orderItemId: j.id,
                                            })),
                                            masterId: i.id,
                                          },
                                        ]);
                                      }}
                                      value={
                                          values.list
                                              ?.filter(ele => ele.masterId === i.id)[0]
                                              ?.items?.filter(
                                              item => item.orderItemId === j.id,
                                          )[0]?.homeDeliveryC || 0
                                      }
                                  >
                                    <option value={0} disabled>
                                      選擇配送商
                                    </option>
                                    {orderOptionsInfo?.homeDeliveryStore?.map(k => (
                                        <option value={k.code} key={k.code}>
                                          {k.name}
                                        </option>
                                    ))}
                                  </Input>
                                  <ErrorText
                                      errors={errors}
                                      touched={touched}
                                      masterIndex={() => handleFindIndex(i.id)}
                                      orderIndex={jIndex}
                                      keyName="homeDeliveryC"
                                  />
                                  {/* 配送編號 */}
                                  <input
                                      type="text"
                                      className="form-control"
                                      onChange={val => {
                                        const index = handleFindIndex(i.id);
                                        index > -1 &&
                                        setFieldValue(
                                            `list[${handleFindIndex(
                                                i.id,
                                            )}].items[${jIndex}].homeDeliveryNumber`,
                                            val.target.value,
                                        );
                                        index <= -1 &&
                                        setFieldValue('list', [
                                          ...values.list,
                                          {
                                            items: i.orderItems.map(j => ({
                                              homeDeliveryC: 0,
                                              homeDeliveryNumber: val.target.value,
                                              orderItemId: j.id,
                                            })),
                                            masterId: i.id,
                                          },
                                        ]);
                                      }}
                                      value={`${
                                          values.list[handleFindIndex(i.id)]?.items[jIndex]
                                              ?.homeDeliveryNumber || ''
                                      }`}
                                  />
                                  <ErrorText
                                      errors={errors}
                                      touched={touched}
                                      masterIndex={() => handleFindIndex(i.id)}
                                      orderIndex={jIndex}
                                      keyName="homeDeliveryNumber"
                                  />
                                </td>
                                <td>
                                  {/* 轉單時間 */}
                                  <div>{i.orderTime || '--'}</div>
                                  {/* 訂單確認時間 */}
                                  <div>{i.checkOrderTime}</div>
                                </td>
                                <td>
                                  <div>{j.deliveryType === 1 ? '一般' : '預購'}</div>
                                  <div>{j.expectedDeliveryDate || '--'}</div>
                                </td>
                                <td>
                                  {/* 商品名稱 */}
                                  <div>{j.productname || '--'}</div>
                                  {/* 商品選項 */}
                                  <div>
                                    {j.color || '--'}/{j.size || '--'}
                                  </div>
                                  {/* 商品料號 */}
                                  <div
                                      className={style.text_dark_info}
                                      style={{ cursor: 'pointer', fontSize: '16px' }}
                                      onClick={() => {
                                        if (Number(j.productType) === 4) {
                                          window.open(
                                              `/#/GiftManagement?page=1&keyword=${
                                                  j.productname || j.freebieCode
                                              }`,
                                              '_blank',
                                          );
                                        } else if (Number(j.productType) === 1) {
                                          window.open(
                                              `/#/ProductControl?page=1&keyword=${
                                                  j.productname || j.productcode
                                              }`,
                                              '_blank',
                                          );
                                        }
                                      }}
                                  >
                                    {Number(j.productType) === 4
                                        ? j.freebieCode || '--'
                                        : j.productcode || '--'}
                                  </div>
                                  {/* 商品屬性 */}
                                  <div>
                                    {Number(j.productType) === 4
                                        ? j.freebieType || '--'
                                        : '商品'}
                                  </div>
                                </td>
                                <td className="text-end">
                                  {/* 數量 */}
                                  <div>{j.quantity}</div>
                                  {/* 銷售金額 */}
                                  <div className="price text-black fw-normal">
                                    {(j.finalPriceUnit || '0').toLocaleString('en-US')}
                                  </div>
                                  <div
                                      className={style.text_dark_info}
                                      style={{
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                      }}
                                      onClick={() => {
                                        modal.showBillInfo({
                                          ...j,
                                          orderNumber: i.orderNumber,
                                        });
                                      }}
                                  >
                                    查看明細
                                  </div>
                                </td>
                                <td className="text-center">
                                  {/* 促銷/贈品 */}
                                  <div
                                      className={style.text_dark_info}
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                      }}
                                      onClick={() => {
                                        onToggleDiscountActivityModal();
                                        setCurrentOrderItemId(j.id);
                                      }}
                                  >
                                    折扣活動明細
                                  </div>
                                </td>
                                <td>
                                  {/* 收件人資訊 */}
                                  <div>{j.name}</div>
                                  <div>{j.phone}</div>
                                  <div>{`${j.cityName}${j.townName}${j.address}`}</div>
                                </td>
                                <td className="text-center">
                                  {/* 訂單狀態 */}
                                  <div>{j.orderItemStatusName || '--'}</div>
                                </td>
                                <td className="text-center">
                                  {/* 商店訂單備註 */}
                                  <div>{j.orderRemark || '--'}</div>
                                  <div
                                      className={style.text_dark_info}
                                      style={{ cursor: 'pointer', fontSize: '14px' }}
                                      onClick={() => {
                                        setOrderInfo({
                                          requestBody: {
                                            orderItemId: j.id,
                                            orderRemark: j.orderRemark,
                                          },
                                          主單編號: i.orderNumber,
                                          商品名稱: j.productname,
                                          訂單編號: j.orderItemNumber,
                                        });
                                        onToggleOrderRemarkModal();
                                      }}
                                  >
                                    編輯備註
                                  </div>
                                </td>
                                <td className="text-center">
                                  {/* 訂購備註 */}
                                  <div>{j.remark || '--'}</div>
                                </td>
                              </tr>
                          ))}
                        </React.Fragment>
                    ))
                ) : (
                    <TableHasNoData />
                )}
                </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
              current={orderListInfo?.current_page || 1}
              pageSize={orderListInfo?.page_size || 10}
              total={orderListInfo?.total_count || 0}
              onChange={onChangePage}
          />
        </div>
      </Card>
  );
};
const HomeDeliveryOrderShippingList = () => {
  const dispatch = useDispatch();
  const { orderOptionsInfo } = useSelector(state => state.orderOptions);
  const [orderInfo, setOrderInfo] = useState(null);
  const [isOrderRemarkModalOpen, setIsOrderRemarkModalOpen] = useToggle();
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
      useToggle();
  const [currentId, setCurrentId] = useState(null);
  const query = useQuery();
  const { location } = history;

  const [currentValues, setCurrentValues] = useState({});

  useEffect(() => {
    dispatch(getOrderOptions());
  }, []);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      deliveryType: 0,
      endTime: null,
      keyword: '',
      logisticsWay: 3,
      orderNumber: '',
      orderNumberType: '主單編號',
      orderStatus: 2,
      page: 1,
      page_size: 10,
      shippingStatus: 1,
      startTime: null,
      timeType: '訂購時間',
    },
    onReset: () => {
      handleSubmit();
    },
    async onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
            ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
            ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };
      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime)
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime)
            : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      dispatch(getOrderList(body));
      setValues({
        ...values,
        ...body,
      });
    } else {
      handleReset();
    }
  }, [location.search]);

  const handleChangePage = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  const { success, error: homeDeliveryShippingError } = useSelector(
      state => state.homeDeliveryShipping,
  );
  const handleShipping = async (params, cb) => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        await dispatch(homeDeliveryShipping(params.list));
        cb();
      },
      title: '確認出貨？',
    });
  };

  const exportOrdersInfoHandler = useCallback(async () => {
    // 帶入當前頁面搜尋條件, 不包含頁碼
    const params = {
      ...values,
      endTime: values.endTime
          ? moment(values.endTime).format('yyyy/MM/DD HH:mm:ss')
          : moment().format('yyyy/MM/DD HH:mm:ss'),
      startTime: values.startTime
          ? moment(values.startTime).format('yyyy/MM/DD HH:mm:ss')
          : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
    };
    isNaN(parseInt(values.logisticsStatus)) && delete params.logisticsStatus;
    isNaN(parseInt(values.shippingStatus)) && delete params.shippingStatus;

    delete params.page;
    try {
      const info = await exportOrdersInfo(params);
      window.open(info, '_blank');
      MessageUtil.alertSuccess('匯出成功!');
    } catch (e) {
      console.log(e);
      MessageUtil.alertWanring('匯出失敗', e);
    }
  }, [values, exportOrdersInfo]);

  useEffect(() => {
    success && MessageUtil.toastSuccess('出貨成功!!');
    success && handleSubmit();
    homeDeliveryShippingError &&
    MessageUtil.alertWanring(homeDeliveryShippingError);
    return () => {
      dispatch({ type: HOME_DELIVERY_SHIPPING_RESET });
    };
  }, [success, homeDeliveryShippingError]);

  return (
      <Layout
          pageTitle="宅配訂單出貨"
          items={[{ isActive: true, page_name: '宅配訂單出貨' }]}
      >
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form>
              <div className={style.show_flex}>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem', width: '100%' }}
                >
                  <label className="col-form-label text-nowrap mr-2">
                    關鍵字
                  </label>
                  <input
                      type="text"
                      className={`form-control ${style['form-keyword']}`}
                      name="keyword"
                      placeholder="會員電話、收件人、收件人電話、商品名稱"
                      onChange={handleChange}
                      value={values.keyword}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2">商品交期</label>
                  <SearchSelect
                      options={orderOptionsInfo?.deliveryType}
                      emptyVal={0}
                      name="deliveryType"
                      setFieldValue={setFieldValue}
                      values={values}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">
                    日期區間
                  </label>
                  <Input
                      type="select"
                      className={style['form-select-medium']}
                      name="timeType"
                      onChange={handleChange}
                      value={values.timeType}
                      style={{ margin: 0 }}
                  >
                    <option value="訂購時間">訂購時間</option>
                    <option value="預購出貨日">預購出貨日</option>
                    <option value="確認時間">確認時間</option>
                    <option value="轉單時間">轉單時間</option>
                  </Input>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.startTime
                            ? moment(values.startTime).toDate()
                            : moment().subtract(7, 'day').toDate()
                      }
                      name="startTime"
                      onChange={date => {
                        setFieldValue('startTime', date);
                      }}
                      onKeyDown={e => e.preventDefault()}
                      selectsStart
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.endTime
                            ? moment(values.endTime).toDate()
                            : moment().toDate()
                      }
                      name="endTime"
                      onChange={date => {
                        setFieldValue('endTime', date);
                      }}
                      onKeyDown={e => e.preventDefault()}
                      selectsEnd
                      minDate={values.startTime}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">編號</label>
                  <Input
                      type="select"
                      className={style['form-select-short']}
                      name="orderNumberType"
                      onChange={handleChange}
                      value={values.orderNumberType}
                      style={{ margin: 0 }}
                  >
                    <option value="主單編號">主單編號</option>
                    <option value="訂單編號">訂單編號</option>
                  </Input>
                  <Input
                      type="text"
                      className={style['form-input-order-number']}
                      name="orderNumber"
                      onChange={handleChange}
                      value={values.orderNumber}
                  />
                </div>
              </div>
            </form>
            <div className={style.button_position}>
              <Button
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    handleReset();
                  }}
              >
                取消
              </Button>
              <Button
                  type="button"
                  className={style.modal_button_submit}
                  onClick={() => {
                    setFieldValue('page', 1);
                    handleSubmit();
                  }}
              >
                查詢
              </Button>
            </div>
          </div>
        </Card>

        <TableList
            onShipping={handleShipping}
            onChangePage={handleChangePage}
            onToggleDiscountModal={setIsDiscountModalOpen}
            onToggleOrderRemarkModal={setIsOrderRemarkModalOpen}
            onExportOrders={exportOrdersInfoHandler}
            setOrderInfo={setOrderInfo}
            onToggleDiscountActivityModal={setIsDiscountActivityModalOpen}
            setCurrentOrderItemId={setCurrentId}
        />
        {isDiscountModalOpen && (
            <DiscountDetailModal
                isOpen={isDiscountModalOpen}
                toggle={setIsDiscountModalOpen}
            />
        )}
        {isOrderRemarkModalOpen && orderInfo && (
            <OrderRemarkModal
                isOpen={isOrderRemarkModalOpen}
                toggle={setIsOrderRemarkModalOpen}
                info={orderInfo}
                callBack={handleSubmit}
            />
        )}
        {isDiscountActivityModalOpen && (
            <DiscountActivityDetailModal
                isOpen={isDiscountActivityModalOpen}
                toggle={setIsDiscountActivityModalOpen}
                id={currentId}
            />
        )}
      </Layout>
  );
};
export default HomeDeliveryOrderShippingList;
