import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import OrderRemarkModal from 'components/OrderRemarkModal';
import DetailModal from 'components/orderDetail/DetailModal';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import RedeemRewardPointsDetailModal from 'components/orderList/RedeemRewardPointsDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportALLOrdersInfo,
  getOrderList,
} from 'redux/actions/data/orderActions';
import { ORDER_LIST_EXPORT_ALL_IDLE } from 'redux/constants/data/orderConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';

import OrderListTable from '../components/orderList/OrderListTable';
import SearchForm from '../components/orderList/SearchForm';

const OrderList = () => {
  const dispatch = useDispatch();

  const {
    error: exportError,
    success: exportSuccess,
    info,
  } = useSelector(state => state.exportAllOrdersInfo);

  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
    useToggle();
  const [isOrderRemarkModalOpen, setIsOrderRemarkModalOpen] = useToggle();
  const [
    isRedeemRewardPointsDetailModalOpen,
    setIsRedeemRewardPointsDetailModalOpen,
  ] = useToggle();
  const [orderInfo, setOrderInfo] = useState(null);
  const [redeemRewardsPointsInfo, setRedeemRewardsPointsInfo] = useState(null);
  const { location } = history;
  const query = useQuery();
  const [currentValues, setCurrentValues] = useState({});

  const {
    values,
    handleSubmit,
    setFieldValue,
    setValues,
    handleReset,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      deliveryType: 0,
      endTime: null,
      keyword: '',
      logisticsStatus: null,
      logisticsWay: 0,
      orderNumber: '',
      orderNumberType: '主單編號',
      orderStatus: 0,
      page: 1,
      page_size: 10,
      payWay: null,
      shippingStatus: null,
      startTime: null,
      timeType: '訂購時間',
    },
    onReset: () => {
      handleSubmit();
    },
    async onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
          ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
          : moment().format('yyyy/MM/DD HH:mm:ss'),
        keyword: `${formModel.keyword}`,
        orderNumber: formModel.orderNumber.trim(),
        startTime: formModel.startTime
          ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
          : moment().add(-7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      isNaN(parseInt(formModel.logisticsStatus)) && delete body.logisticsStatus;
      isNaN(parseInt(formModel.shippingStatus)) && delete body.shippingStatus;

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
          ? JSON.parse(query.value.endTime)
          : moment().format('yyyy/MM/DD HH:mm:ss'),
        keyword: `${query.value.keyword || ''}`,
        orderNumber: `${query.value.orderNumber || ''}`,
        startTime: query.value.startTime
          ? JSON.parse(query.value.startTime)
          : moment().add(-7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      isNaN(parseInt(body.logisticsStatus)) && delete body.logisticsStatus;
      isNaN(parseInt(body.shippingStatus)) && delete body.shippingStatus;

      dispatch(getOrderList(body));
      setValues(body);
    } else {
      handleReset();
    }
  }, [location.search]);

  useEffect(() => {
    if (exportSuccess) {
      CommonUtil.consoleLog({ anno: 'reponse', data: info });
      window.open(info);
      dispatch({ type: ORDER_LIST_EXPORT_ALL_IDLE });
      MessageUtil.toastSuccess('匯出成功!');
    }
  }, [exportSuccess]);

  useEffect(() => {
    if (exportError) {
      dispatch({ type: ORDER_LIST_EXPORT_ALL_IDLE });
      MessageUtil.alertWanring('匯出失敗', exportError);
    }
  }, [exportError]);

  const handleToDetail = id => {
    history.push(`/OrderDetail/${id}`);
    // 保存搜尋條件
    sessionStorage.setItem('condition', JSON.stringify(values));
  };
  const handleChangePage = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  const enteredValueCheckingHandler = event => {
    const space = /\s/g;
    const inputValue = event.target.value.replace(space, '');
    event.target.value = inputValue;
  };

  const exportOrdersInfoHandler = () => {
    // 帶入當前頁面搜尋條件, 不包含頁碼
    const params = {
      ...values,
      endTime: values.endTime
        ? moment(values.endTime).format('yyyy/MM/DD HH:mm:ss')
        : moment().format('yyyy/MM/DD HH:mm:ss'),
      keyword: `${values.keyword}`,
      startTime: values.startTime
        ? moment(values.startTime).format('yyyy/MM/DD HH:mm:ss')
        : moment().add(-7, 'days').format('yyyy/MM/DD HH:mm:ss'),
    };
    isNaN(parseInt(values.logisticsStatus)) && delete params.logisticsStatus;
    isNaN(parseInt(values.shippingStatus)) && delete params.shippingStatus;
    delete params.page;
    dispatch(exportALLOrdersInfo(params));
  };

  const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useToggle();
  const [giftType, setGiftType] = useState('');

  return (
    <Layout
      pageTitle="所有訂單查詢"
      items={[{ isActive: true, page_name: '所有訂單查詢' }]}
    >
      <SearchForm
        handleSubmit={handleSubmit}
        handleReset={handleReset}
        setFieldValue={setFieldValue}
        values={values}
        enteredValueCheckingHandler={enteredValueCheckingHandler}
      />
      <OrderListTable
        exportOrdersInfoHandler={exportOrdersInfoHandler}
        setOrderInfo={setOrderInfo}
        handleChangePage={handleChangePage}
        setIsOrderRemarkModalOpen={setIsOrderRemarkModalOpen}
        setIsRedeemRewardPointsDetailModalOpen={
          setIsRedeemRewardPointsDetailModalOpen
        }
        setRedeemRewardsPointsInfo={setRedeemRewardsPointsInfo}
        setIsDiscountModalOpen={setIsDiscountModalOpen}
        setIsDiscountActivityModalOpen={setIsDiscountActivityModalOpen}
        setIsDetailModalOpen={setIsDetailModalOpen}
        handleToDetail={handleToDetail}
        setGiftType={setGiftType}
        setCurrentDiscountInfo={setCurrentDiscountInfo}
      />
      {isDiscountModalOpen && (
        <DiscountDetailModal
          isOpen={isDiscountModalOpen}
          toggle={setIsDiscountModalOpen}
          id={currentDiscountInfo}
        />
      )}
      {isOrderRemarkModalOpen && orderInfo && (
        <OrderRemarkModal
          isOpen={isOrderRemarkModalOpen}
          toggle={setIsOrderRemarkModalOpen}
          info={orderInfo}
          callBack={handleSubmit}
        />
      )}
      {isDiscountActivityModalOpen && (
        <DiscountActivityDetailModal
          isOpen={isDiscountActivityModalOpen}
          toggle={setIsDiscountActivityModalOpen}
          id={currentDiscountInfo}
        />
      )}
      {isRedeemRewardPointsDetailModalOpen && (
        <RedeemRewardPointsDetailModal
          isOpen={isRedeemRewardPointsDetailModalOpen}
          toggle={setIsRedeemRewardPointsDetailModalOpen}
          id={currentDiscountInfo}
          data={redeemRewardsPointsInfo}
        />
      )}
      {isDetailModalOpen && (
        <DetailModal
          isOpen={isDetailModalOpen}
          toggle={setIsDetailModalOpen}
          type={giftType}
        />
      )}
    </Layout>
  );
};
export default OrderList;
