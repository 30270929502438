import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Input} from 'reactstrap';

import {UpdateMessageNote} from '../../redux/actions/data/messageActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';

const ProblemDetail = ({
  datas,
  setCurrentImgSrc,
  setIsOriginImageModalOpen,
}) => {
  const [note , setNote] = useState(datas.note || '');
  const [loading , setLoading] = useState(false);

  useEffect(()=>{
    setNote(datas.note || '')
  },[datas])

  const onSubmit = useCallback(async ()=>{
    setLoading(true)
    try{
      await UpdateMessageNote(datas.messageId , note)
      MessageUtil.alertSuccess('更新成功');
    }catch (e){
      MessageUtil.alertWanring('更新失敗',e);
    }
    setLoading(false)
  },[datas , note])

  return (
    <Card className={style.search_card}>
      <div className={style.card_header}>問題明細</div>
      <div className={style.card_body}>
        <div className={style.formGroup}>
          <label className="w100">問題單編號</label>
          <div>{datas.messageNum}</div>
        </div>
        <div className={style.formGroup}>
          <label className="w100">會員編號&電話</label>
          <div>{`0${datas.memberNumber}`}</div>
        </div>
        <div className={style.formGroup}>
          <label className="w100">姓名</label>
          <div>{datas.memberName}</div>
        </div>
        <div className={style.formGroup}>
          <label className="w100">提問時間</label>
          <div>{datas.createTime}</div>
        </div>
        <div className={style.formGroup}>
          <label className="w100">問題分類</label>
          <div>{`${datas.cat1Name} > ${datas.cat2Name}`}</div>
        </div>
        <div className={style.formGroup}>
          <label className="w100">訂單編號</label>
          <div>{datas.orderNumber || datas.repairRegisterNum}</div>
        </div>
        <div className={style.formGroup}>
          <label className="w100">提問內容</label>
          <div>{datas.context}</div>
        </div>
        <div className="d-flex gap-2">
          <label className="w100">備註</label>
          <Input
              tag="textarea"
              row={3}
              className={style['form-input']}
              value={note}
              onChange={event => {
                setNote( event.target.value);
              }}
          />
          <Button
              className="align-self-start"
              type="button"
              color="primary"
              disabled={loading}
              onClick={onSubmit}
          >
            儲存
          </Button>
        </div>
        <div className={style.formGroup}>
          <label className="w100" />
          {datas?.messageImgs?.length > 0 &&
            datas.messageImgs.map(img => (
              <img
                src={img}
                alt={`${img}`}
                style={{ cursor: 'pointer', height: '100px', width: '100px' }}
                className="me-2"
                onClick={() => {
                  setCurrentImgSrc(img);
                  setIsOriginImageModalOpen();
                }}
              />
            ))}
        </div>
      </div>
    </Card>
  );
};

export default ProblemDetail;
