import BillInfo from 'components/BillInfo';
import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import OrderRemarkModal from 'components/OrderRemarkModal';
import SearchSelect from 'components/OrderSearchSelector';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Input, InputGroup, Table } from 'reactstrap';
import { createLogisticsShipping } from 'redux/actions/data/createLogisticsActions';
import { getOrderList, getOrderOptions } from 'redux/actions/data/orderActions';
import { exportOrdersInfo } from 'redux/actions/data/orderActions';
import { CREATE_LOGISTICS_SHIPPING_RESET } from 'redux/constants/data/createLogisticsConstants';
import { createHiddenInput } from 'utils';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import request from 'utils/useAxios';
import useQuery from 'utils/useQuery';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const TableList = ({
                     onShippingOne,
                     onShipping,
                     onChangePage,
                     onToggleDiscountModal,
                     onToggleOrderRemarkModal,
                     onToggleBillInfoModal,
                     setOrderInfo,
                     setBillInfo,
                     onExportOrders,
                     setCurrentOrderId,
                     onToggleDiscountActivityModal,
                   }) => {
  const { loading, orderListInfo } = useSelector(state => state.orderList);

  const { values, setFieldValue, handleSubmit, isSubmitting } = useFormik({
    enableReinitialize: true,
    initialValues: {
      all: false,
      list: [],
    },
    async onSubmit(formModel, { resetForm }) {
      await onShipping(formModel);
      resetForm();
    },
  });

  const handleToDetail = id => {
    history.push(`/OrderDetail/${id}`);
  };
  const handleToShippingPrintInfo = () => {
    history.push('/ShippingPrintInfo');
  };
  const handlePrintDetail = () => {
    const params = new URLSearchParams();
    values.list.forEach(i => {
      params.append('orderNumber', i.id);
    });
    const win = window.open(
      `/#/OrderPrintDetail?${params.toString()}`,
      '_blank',
    );
    win.focus();
  };

  const handlePrintOrderInfo = async item => {
    const { code, message, result } = await request({
      data: [item.orderNumber],
      method: 'post',
      url: '/Website/CreateLogistics/PrintFAMIC2COrderInfo',
    });
    if (code !== 200) {
      MessageUtil.alertWanring('發生錯誤！', message);
      return;
    }
    const form = document.createElement('form');
    form.method = 'POST';
    form.target = '_blank';
    form.action = `${process.env.REACT_APP_EC_PAY}/Express/PrintFAMIC2COrderInfo`
    createHiddenInput(form, 'AllPayLogisticsID', result.allPayLogisticsID);
    createHiddenInput(form, 'CheckMacValue', result.checkMacValue);
    createHiddenInput(form, 'CVSPaymentNo', result.cvsPaymentNo);
    createHiddenInput(form, 'MerchantID', result.merchantID);
    createHiddenInput(form, 'PlatformID', result.platformID);
    document.body.appendChild(form);
    form.submit();
  };

  const isSticky = orderListInfo?.datas?.length > 0 ? { height: '80vh' } : {};

  console.log(values.list);

  return (
    <Card className={style.card}>
      <div
        className={`${style.show_flex} mb-3`}
        style={{ position: 'relative' }}
      >
        <Button
          className="me-2 w100"
          color="success"
          onClick={handleSubmit}
          disabled={values.list.length <= 0}
          style={{ margin: '0.2rem' }}
        >
          出貨
        </Button>
        <Button
          className="me-2"
          disabled={values.list.length <= 0}
          onClick={handlePrintDetail}
          style={{ margin: '0.2rem' }}
        >
          列印出貨明細單
        </Button>
        <Button
          className="me-2 w100"
          onClick={() => {
            onExportOrders();
          }}
        >
          匯出訂單
        </Button>
        <div
          className={style.show_flex}
          style={{ bottom: '0.5rem', position: 'absolute', right: '1rem' }}
        >
          <u
            className={style.text_dark_info}
            style={{ cursor: 'pointer', fontSize: '16px', textAlign: 'center' }}
            onClick={handleToShippingPrintInfo}
          >
            補印標籤 及 出貨明細
          </u>
        </div>
      </div>
      <div className="table-responsive mb-5" style={isSticky}>
        <Table hover bordered striped>
          <thead
            className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
          >
          <tr>
            <th>
              <input
                className={style.maintain_list_checkbox}
                type="checkbox"
                checked={
                  values.list.length > 0 &&
                  values.list.length === orderListInfo?.datas?.length
                }
                name="all"
                onChange={e => {
                  const val = e.target.checked;
                  setFieldValue(
                    'list',
                    val
                      ? orderListInfo?.datas?.map(i => ({
                        id: i.id,
                        orderNumber: i.orderNumber,
                      }))
                      : [],
                  );
                }}
              />{' '}
              全選
            </th>
            <th>
              <div>主單編號</div>
              <div>訂單編號</div>
            </th>
            <th>
              <div>配送資訊</div>
            </th>
            <th>
              <div>轉單時間</div>
              <div>配號時間</div>
              <div>寄件起始日</div>
              <div>寄件截止日</div>
            </th>
            <th>
              <div>出貨單狀態</div>
              <div>交期</div>
              <div>預計出貨日</div>
            </th>
            <th>
              <div>商品名稱</div>
              <div>商品選項</div>
              <div>商品料號</div>
              <div>商品屬性</div>
            </th>
            <th>
              <div>數量</div>
              <div>銷售金額</div>
            </th>
            <th>促銷/贈品</th>
            <th>
              <div>收件人資訊</div>
              <div>取貨門市</div>
              <div>門市地址</div>
            </th>
            <th>訂單狀態</th>
            <th>商店訂單備註</th>
          </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
            {orderListInfo?.datas?.length > 0 ? (
              orderListInfo?.datas?.map(i => (
                <React.Fragment key={JSON.stringify(i)}>
                  <tr className="text-nowrap bg">
                    <td>
                      <input
                        className={style.maintain_list_checkbox}
                        type="checkbox"
                        onChange={e => {
                          const val = e.target.checked;
                          setFieldValue(
                            'list',
                            val
                              ? [
                                ...values.list,
                                { id: i.id, orderNumber: i.orderNumber },
                              ]
                              : values.list.filter(j => j.id !== i.id),
                          );
                        }}
                        checked={values.list.some(j => j.id === i.id)}
                      />
                    </td>
                    <td colSpan="2">
                      <div>配送編號：{i.orderItems[0].cvsPaymentNo}</div>
                    </td>
                    <td colSpan={8}>
                      <div className="d-flex align-items-center">
                        <Button
                          color="success"
                          outline
                          className="bg-white text-success me-2"
                          onClick={() => onShippingOne(i)}
                        >
                          出貨
                        </Button>
                        <Button
                          color="success"
                          outline
                          className="bg-white text-success me-5"
                          // disabled={
                          //   !values.list.some(
                          //     k => k.orderNumber === i.orderNumber,
                          //   )
                          // }
                          onClick={() => handlePrintOrderInfo(i)}
                        >
                          列印標籤
                        </Button>
                        <div className="me-auto">
                          門市列印專用代碼：{i.shipmentNo?? '--'} /
                          代收金額：
                          <span className="price text-black fw-normal">
                              {i.actualAmount.toLocaleString('en-US')}
                            </span>
                        </div>
                        <FaChevronDown id={`orderId${i.id}`} />
                      </div>
                    </td>
                  </tr>
                  {i.orderItems.map((j, jIndex) => (
                    <tr key={jIndex}>
                      <td></td>
                      <td>
                        {/* 主單編號 */}
                        <div>{i.orderNumber}</div>
                        {/* 訂單編號 */}
                        <div>{j.orderItemNumber}</div>
                      </td>
                      <td>
                        {/* 配送資訊 */}
                        <div>{j.logisticsWayName || '--'}</div>
                        <div>主單運費</div>
                        <div className="price text-black fw-normal">
                          {i.deliveryCharge}
                        </div>
                      </td>
                      <td>
                        {/* 轉單時間 */}
                        <div>{i.orderTime}</div>
                        {/* 配號時間 */}
                        <div>{i.checkOrderTime}</div>
                        {/* 寄件起始日 */}
                        <div>{i.cstoreSendStartTime || '--'}</div>
                        {/* 寄件截止日 */}
                        <div>{i.cstoreSendEndTime || '--'}</div>
                      </td>
                      <td>
                        {/* 出貨單狀態 */}
                        <div>{j.shippingStatusName}</div>
                        {/* 交期 */}
                        <div>{j.deliveryType === 1 ? '一般' : '預購'}</div>
                        {/* 預計出貨日 */}
                        <div>{j.expectedDeliveryDate || '--'}</div>
                      </td>
                      <td>
                        {/* 商品名稱 */}
                        <div>{j.productname || '--'}</div>
                        {/* 商品選項 */}
                        <div>
                          {j.color || '--'}/{j.size || '--'}
                        </div>
                        {/* 商品料號 */}
                        <div
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            fontSize: '16px',
                          }}
                          onClick={() => {
                            if (Number(j.productType) === 4) {
                              window.open(
                                `/#/GiftManagement?page=1&keyword=${
                                  j.productname || j.freebieCode
                                }`,
                                '_blank',
                              );
                            } else if (Number(j.productType) === 1) {
                              window.open(
                                `/#/ProductControl?page=1&keyword=${
                                  j.productname || j.productcode
                                }`,
                                '_blank',
                              );
                            }
                          }}
                        >
                          {Number(j.productType) === 4
                            ? j.freebieCode || '--'
                            : j.productcode || '--'}
                        </div>
                        {/* 商品屬性 */}
                        <div>
                          {Number(j.productType) === 4
                            ? j.freebieType || '--'
                            : '商品'}
                        </div>
                      </td>
                      <td className="text-end">
                        {/* 數量 */}
                        <div>{j.quantity}</div>
                        {/* 銷售金額 */}
                        <div className="price text-black fw-normal">
                          {(j.finalPriceUnit || '0').toLocaleString('en-US')}
                        </div>
                        <div
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textAlign: 'center',
                          }}
                          onClick={() => {
                            onToggleBillInfoModal();
                            setBillInfo({
                              amount: j.finalPrice,
                              price: {
                                商品單價: j.unitPrice,
                                折扣金額: j.discountAmountUnit,
                              },
                              productInfo: {
                                主單編號: i.orderNumber,
                                商品名稱: j.productname,
                                數量: j.quantity,
                                訂單編號: j.orderItemNumber,
                              },
                            });
                          }}
                        >
                          查看明細
                        </div>
                      </td>
                      <td className="text-center">
                        {/* 促銷/贈品 */}
                        <div
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            fontSize: '14px',
                          }}
                          onClick={() => {
                            setCurrentOrderId(j.id);
                            onToggleDiscountActivityModal();
                          }}
                        >
                          折扣活動明細
                        </div>
                      </td>
                      <td>
                        {/* 收件人資訊 */}
                        <div>{j.name}</div>
                        <div>{j.phone}</div>
                        <div>{`${j.cityName || '--'}${j.townName || '--'}${
                          j.address || '--'
                        }`}</div>
                        {/* 取貨門市 */}
                        <div>{j.cvsStoreName}</div>
                        {/* 門市地址 */}
                        <div>{j.cvsAddress}</div>
                      </td>
                      <td>
                        {/* 訂單狀態 */}
                        <div>{j.orderItemStatusName}</div>
                      </td>
                      <td className="text-center">
                        {/* 商店訂單備註 */}
                        <div>{j.orderRemark || '--'}</div>
                        <div
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            fontSize: '14px',
                          }}
                          onClick={() => {
                            setOrderInfo({
                              requestBody: {
                                orderItemId: j.id,
                                orderRemark: j.orderRemark,
                              },
                              主單編號: i.orderNumber,
                              商品名稱: j.productname,
                              訂單編號: j.orderItemNumber,
                            });
                            onToggleOrderRemarkModal();
                          }}
                        >
                          編輯備註
                        </div>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <TableHasNoData />
            )}
            </tbody>
          )}
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <RcPagination
          current={orderListInfo?.current_page || 1}
          pageSize={orderListInfo?.page_size || 10}
          total={orderListInfo?.total_count || 0}
          onChange={onChangePage}
        />
      </div>
    </Card>
  );
};
const CreateLogisticsShipping = () => {
  const dispatch = useDispatch();
  const [orderInfo, setOrderInfo] = useState(null);
  const [billInfo, setBillInfo] = useState(null);
  const [isOrderRemarkModalOpen, setIsOrderRemarkModalOpen] = useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isBillInfoModalOpen, setIsBillInfoModalOpen] = useState(false);
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
    useToggle();
  const [currentId, setCurrentId] = useState(null);
  const { orderOptionsInfo } = useSelector(state => state.orderOptions);
  const [currentValues, setCurrentValues] = useState({});
  const query = useQuery();
  const { location } = history;
  useEffect(() => {
    dispatch(getOrderOptions());
  }, []);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      deliveryType: 0,
      endTime: null,
      keyword: '',
      logisticsWay: 2,
      orderNumber: '',
      orderNumberType: '主單編號',
      orderStatus: 2,
      page: 1,
      page_size: 10,
      shippingStatus: 1,
      startTime: null,
      timeType: '訂購時間',
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
          ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
          : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
          ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
          : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(`${location.pathname}?${params.toString()}`);
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
          ? JSON.parse(query.value.endTime)
          : moment().format('yyyy/MM/DD HH:mm:ss'),
        keyword: `${query.value.keyword || ''}`,
        orderNumber: `${query.value.orderNumber || ''}`,
        startTime: query.value.startTime
          ? JSON.parse(query.value.startTime)
          : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      dispatch(getOrderList(body));
      setValues(body);
    } else {
      handleReset();
    }
  }, [location.search]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const { success, error } = useSelector(
    state => state.createLogisticsShipping,
  );
  const handleShipping = (params, cb) => {
    MessageUtil.submitConfirm({
      doneFun: () => {
        dispatch(createLogisticsShipping(params.list.map(i => i.orderNumber)));
        cb();
      },
      title: '確認出貨？',
    });
  };

  const handleShippingOne = item => {
    MessageUtil.submitConfirm({
      doneFun: () => {
        const params = [item.orderNumber];
        dispatch(createLogisticsShipping(params));
      },
      title: '確認出貨？',
    });
  };

  const toggleDiscountModal = () => {
    setIsDiscountModalOpen(!isDiscountModalOpen);
  };

  const toggleOrderRemarkModal = () => {
    setIsOrderRemarkModalOpen(!isOrderRemarkModalOpen);
  };

  const toggleBillInfoModal = () => {
    setIsBillInfoModalOpen(!isBillInfoModalOpen);
  };

  const exportOrdersInfoHandler = useCallback(async () => {
    // 帶入當前頁面搜尋條件, 不包含頁碼
    const params = {
      ...values,
      endTime: values.endTime
        ? moment(values.endTime).format('yyyy/MM/DD HH:mm:ss')
        : moment().format('yyyy/MM/DD HH:mm:ss'),
      startTime: values.startTime
        ? moment(values.startTime).format('yyyy/MM/DD HH:mm:ss')
        : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
    };
    isNaN(parseInt(values.logisticsStatus)) && delete params.logisticsStatus;
    isNaN(parseInt(values.shippingStatus)) && delete params.shippingStatus;

    delete params.page;

    try {
      const info = await exportOrdersInfo(params);
      window.open(info, '_blank');
      MessageUtil.alertSuccess('匯出成功!');
    } catch (e) {
      console.log(e);
      MessageUtil.alertWanring('匯出失敗', e);
    }
  }, [values, exportOrdersInfo]);

  useEffect(() => {
    success && MessageUtil.toastSuccess('出貨成功!!');
    success && handleSubmit();
    return () => {
      dispatch({ type: CREATE_LOGISTICS_SHIPPING_RESET });
    };
  }, [success]);
  useEffect(() => {
    error && MessageUtil.alertWanring(error.message || '發生錯誤！');
    return () => {
      dispatch({ type: CREATE_LOGISTICS_SHIPPING_RESET });
    };
  }, [error]);

  return (
    <Layout
      pageTitle="全家店到店出貨 - 2. 標籤列印/出貨確認"
      items={[
        { isActive: true, page_name: '全家店到店出貨 - 2. 標籤列印/出貨確認' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>
            本頁面只出現主單狀態為【已確認】之訂單
          </span>
        </div>
      </Alert>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label text-nowrap mr-2">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-keyword']}`}
                  name="keyword"
                  placeholder="會員電話、收件人、收件人電話、商品名稱"
                  onChange={handleChange}
                  value={values.keyword}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2">商品交期</label>
                <SearchSelect
                  className={style['form-select-short']}
                  options={orderOptionsInfo?.deliveryType}
                  emptyVal={0}
                  name="deliveryType"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label text-nowrap mr-2">
                  日期區間
                </label>
                <Input
                  type="select"
                  className={style['form-select-medium']}
                  name="timeType"
                  onChange={handleChange}
                  value={values.timeType}
                  style={{ margin: 0 }}
                >
                  <option value="訂購時間">訂購時間</option>
                  <option value="預購出貨日">預購出貨日</option>
                  <option value="確認時間">配號時間</option>
                  {/* <option value="轉單時間">轉單時間</option> */}
                </Input>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd p"
                  popperClassName={style['form-control-date-picker']}
                  showTimeSelect
                  selected={
                    values.startTime
                      ? moment(values.startTime).toDate()
                      : moment().subtract(7, 'day').toDate()
                  }
                  name="startTime"
                  onChange={date => {
                    setFieldValue('startTime', date);
                  }}
                  selectsStart
                />
                <span style={{ margin: '1rem' }}>~</span>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd p"
                  popperClassName={style['form-control-date-picker']}
                  showTimeSelect
                  selected={
                    values.endTime
                      ? moment(values.endTime).toDate()
                      : moment().toDate()
                  }
                  name="endTime"
                  onChange={date => {
                    setFieldValue('endTime', date);
                  }}
                  selectsEnd
                  minDate={values.startTime}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">編號</label>
                <InputGroup>
                  <Input
                    type="select"
                    className={style['form-select-short']}
                    name="orderNumberType"
                    onChange={handleChange}
                    value={values.orderNumberType}
                    style={{ margin: 0 }}
                  >
                    <option value="主單編號">主單編號</option>
                    <option value="訂單編號">訂單編號</option>
                  </Input>
                  <Input
                    type="text"
                    className={style['form-input-order-number']}
                    name="orderNumber"
                    onChange={handleChange}
                    value={values.orderNumber}
                  />
                </InputGroup>
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={() => {
                setFieldValue('page', 1);
                handleSubmit();
              }}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>

      <TableList
        onShippingOne={handleShippingOne}
        onShipping={handleShipping}
        onChangePage={handleChangePage}
        onToggleDiscountModal={toggleDiscountModal}
        onToggleOrderRemarkModal={toggleOrderRemarkModal}
        onToggleBillInfoModal={toggleBillInfoModal}
        onExportOrders={exportOrdersInfoHandler}
        setOrderInfo={setOrderInfo}
        setBillInfo={setBillInfo}
        setCurrentOrderId={setCurrentId}
        onToggleDiscountActivityModal={setIsDiscountActivityModalOpen}
      />
      {isDiscountModalOpen && (
        <DiscountDetailModal
          isOpen={isDiscountModalOpen}
          toggle={toggleDiscountModal}
        />
      )}
      {isOrderRemarkModalOpen && orderInfo && (
        <OrderRemarkModal
          isOpen={isOrderRemarkModalOpen}
          toggle={toggleOrderRemarkModal}
          info={orderInfo}
          callBack={handleSubmit}
        />
      )}
      {isBillInfoModalOpen && (
        <BillInfo
          isOpen={isBillInfoModalOpen}
          toggle={toggleBillInfoModal}
          info={billInfo}
        />
      )}
      {isDiscountActivityModalOpen && (
        <DiscountActivityDetailModal
          isOpen={isDiscountActivityModalOpen}
          toggle={setIsDiscountActivityModalOpen}
          id={currentId}
        />
      )}
    </Layout>
  );
};
export default CreateLogisticsShipping;
