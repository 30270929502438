import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import OrderRemarkModal from 'components/OrderRemarkModal';
import SearchSelect from 'components/OrderSearchSelector';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Input, Table } from 'reactstrap';
import { getOrderList, getOrderOptions } from 'redux/actions/data/orderActions';
import CommonUtil from 'utils/CommonUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const TableList = ({
                     onChangePage,
                     onToggleOrderRemarkModal,
                     setOrderInfo,
                     onToggleDiscountActivityModal,
                     setCurrentOrderItemId,
                   }) => {
  const { loading, orderListInfo, error } = useSelector(
      state => state.orderList,
  );

  const isSticky = orderListInfo?.datas?.length > 0 ? { height: '80vh' } : {};

  return (
      <Card className={style.card}>
        <div className="table-responsive mb-5" style={isSticky}>
          <Table hover bordered striped>
            <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
            <tr>
              <th>
                <div>主單編號</div>
                <div>訂單編號</div>
              </th>
              <th>
                <div>配送資訊</div>
              </th>
              <th>
                <div>轉單時間</div>
                <div>配號時間</div>
                <div>寄件起始日</div>
                <div>寄件截止日</div>
              </th>
              <th>
                <div>退貨狀態</div>
                <div>退貨日期</div>
                <div>退貨門市取件期限</div>
                <div>退貨取件門市</div>
              </th>
              <th>
                <div>出貨單狀態</div>
                <div>交期</div>
                <div>預計出貨日</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                {/* <div>商品單價</div>
                <div>折扣金額</div> */}
                <div>銷售金額</div>
              </th>
              <th>
                <div>促銷/蹭品</div>
              </th>
              <th>
                <div>收件人資訊</div>
                <div>取貨門市</div>
                <div>門市地址</div>
              </th>
              <th>
                <div>訂單狀態</div>
                <div>訂單來源</div>
              </th>
              <th>
                <div>商店訂單備註</div>
              </th>
            </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
                <tbody>
                {orderListInfo?.datas?.length > 0 ? (
                    orderListInfo?.datas?.map(i => (
                        <React.Fragment key={JSON.stringify(i)}>
                          <tr className="text-nowrap bg">
                            <td colSpan="2">
                              <div>配送編號：{i.orderItems[0].cvsPaymentNo}</div>
                            </td>
                            <td colSpan={9} className="text-end">
                              <FaChevronDown id={`orderId${i.id}`} />
                            </td>
                          </tr>
                          {i.orderItems.map((j, jIndex) => (
                              <tr key={jIndex}>
                                <td>
                                  {/* 主單編號 */}
                                  <div>{i.orderNumber}</div>
                                  {/* 訂單編號 */}
                                  <div>{j.orderItemNumber}</div>
                                </td>
                                <td>
                                  {/* 配送資訊 */}
                                  <div>{j.logisticsWayName || '--'}</div>
                                  <div>主單運費</div>
                                  <div className="price text-black fw-normal">
                                    {i.deliveryCharge}
                                  </div>
                                </td>
                                <td>
                                  {/* 轉單時間 */}
                                  <div>{i.orderTime}</div>
                                  {/* 配號時間 */}
                                  <div>{i.checkOrderTime}</div>
                                  {/* 寄件起始日 */}
                                  <div>{i.cstoreSendStartTime || '--'}</div>
                                  {/* 寄件截止日 */}
                                  <div>{i.cstoreSendEndTime || '--'}</div>
                                </td>
                                <td>
                                  {/* 退貨狀態 */}
                                  <div>{j.logisticsStatusName || '--'}</div>
                                  {/* 退貨日期 */}
                                  <div>{j.logisticsStatusDate || '--'}</div>
                                  {/* 退貨門市取件期限 */}
                                  <div>{j.pickUpDeadline}</div>
                                  {/* 退貨取件門市 */}
                                  <div>{j.cvsStoreName}</div>
                                </td>
                                <td>
                                  {/* 出貨單狀態 */}
                                  <div>{j.shippingStatusName}</div>
                                  {/* 交期 */}
                                  <div>{j.deliveryType === 1 ? '一般' : '預購'}</div>
                                  {/* 預計出貨日 */}
                                  <div>{j.expectedDeliveryDate || '--'}</div>
                                </td>
                                <td>
                                  {/* 商品名稱 */}
                                  <div>{j.productname || '--'}</div>
                                  {/* 商品選項 */}
                                  <div>
                                    {j.color || '--'}/{j.size || '--'}
                                  </div>
                                  {/* 商品料號 */}
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                      }}
                                      onClick={() => {
                                        if (Number(j.productType) === 4) {
                                          window.open(
                                              `/#/GiftManagement?page=1&keyword=${
                                                  j.productname || j.freebieCode
                                              }`,
                                              '_blank',
                                          );
                                        } else if (Number(j.productType) === 1) {
                                          window.open(
                                              `/#/ProductControl?page=1&keyword=${
                                                  j.productname || j.productcode
                                              }`,
                                              '_blank',
                                          );
                                        }
                                      }}
                                  >
                                    {Number(j.productType) === 4
                                        ? j.freebieCode || '--'
                                        : j.productcode || '--'}
                                  </div>
                                  {/* 商品屬性 */}
                                  <div>
                                    {Number(j.productType) === 4
                                        ? j.freebieType || '--'
                                        : '商品'}
                                  </div>
                                </td>
                                <td className="text-end">
                                  {/* 數量 */}
                                  <div>{j.quantity}</div>
                                  {/* 銷售金額 */}
                                  <div className="price text-black fw-normal">
                                    {(j.finalPriceUnit || '0').toLocaleString('en-US')}
                                  </div>
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                      }}
                                      onClick={() => {
                                        modal.showBillInfo({
                                          ...j,
                                          orderNumber: i.orderNumber,
                                        });
                                      }}
                                  >
                                    查看明細
                                  </div>
                                </td>
                                <td className="text-center">
                                  {/* 促銷/贈品 */}
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                      }}
                                      onClick={() => {
                                        setCurrentOrderItemId(j.id);
                                        onToggleDiscountActivityModal();
                                      }}
                                  >
                                    折扣活動明細
                                  </div>
                                </td>
                                <td>
                                  {/* 收件人資訊 */}
                                  <div>{j.name}</div>
                                  <div>{j.phone}</div>
                                  <div>{`${j.cityName || '--'}${j.townName || '--'}${
                                      j.address || '--'
                                  }`}</div>
                                  {/* 取貨門市 */}
                                  <div>{j.cvsStoreName}</div>
                                  {/* 門市地址 */}
                                  <div>{j.cvsAddress}</div>
                                </td>
                                <td>
                                  {/* 訂單狀態 */}
                                  <div>{j.orderItemStatusName}</div>
                                  {/* 訂單來源 */}
                                  <div>--</div>
                                </td>
                                <td className="text-center">
                                  {/* 商店訂單備註 */}
                                  <div>{j.orderRemark || '--'}</div>
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                      }}
                                      onClick={() => {
                                        setOrderInfo({
                                          requestBody: {
                                            orderItemId: j.id,
                                            orderRemark: j.orderRemark,
                                          },
                                          主單編號: i.orderNumber,
                                          商品名稱: j.productname,
                                          訂單編號: j.orderItemNumber,
                                        });
                                        onToggleOrderRemarkModal();
                                      }}
                                  >
                                    編輯備註
                                  </div>
                                </td>
                              </tr>
                          ))}
                        </React.Fragment>
                    ))
                ) : (
                    <TableHasNoData />
                )}
                {error && <TableHasNoData />}
                </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
              current={orderListInfo?.current_page || 1}
              pageSize={orderListInfo?.page_size || 10}
              total={orderListInfo?.total_count || 0}
              onChange={onChangePage}
          />
        </div>
      </Card>
  );
};
const CreateLogisticsFailSeven = () => {
  const dispatch = useDispatch();
  const [orderInfo, setOrderInfo] = useState(null);
  const [isOrderRemarkModalOpen, setIsOrderRemarkModalOpen] = useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
      useToggle();
  const [currentId, setCurrentId] = useState(null);
  const { orderOptionsInfo } = useSelector(state => state.orderOptions);

  const query = useQuery();
  const { location } = history;

  const [currentValues, setCurrentValues] = useState({});

  useEffect(() => {
    dispatch(getOrderOptions());
  }, []);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      deliveryType: 0,
      endTime: null,
      hasReturned: true,
      keyword: '',
      logisticsWay: 1,
      orderNumber: '',
      orderNumberType: '主單編號',
      orderStatus: 2,
      page: 1,
      page_size: 10,
      shippingStatus: 2,
      startTime: null,
      timeType: '訂購時間',
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
            ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
            ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime)
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        hasReturned: true,
        keyword: `${query.value.keyword || ''}`,
        orderNumber: `${query.value.orderNumber || ''}`,
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime)
            : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      dispatch(getOrderList(body));
      setValues(body);
    } else {
      handleReset();
    }
  }, [location.search]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const toggleDiscountModal = () => {
    setIsDiscountModalOpen(!isDiscountModalOpen);
  };

  const toggleOrderRemarkModal = () => {
    setIsOrderRemarkModalOpen(!isOrderRemarkModalOpen);
  };

  return (
      <Layout
          pageTitle="7-11店到店出貨 - 出貨失敗 - 待取貨"
          items={[
            { isActive: true, page_name: '7-11店到店出貨 - 出貨失敗 - 待取貨' },
          ]}
      >
        <Alert color="secondary">
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>
            本頁面只出現超商驗退後，包裹已退回原寄件門市，並已發送簡訊給超取窗口之訂單
          </span>
          </div>
        </Alert>
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form>
              <div className="d-flex flex-wrap w-100">
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label text-nowrap mr-2">
                    關鍵字
                  </label>
                  <input
                      type="text"
                      className={`form-control ${style['form-keyword']}`}
                      name="keyword"
                      placeholder="會員電話、收件人、收件人電話、商品名稱、品項編碼"
                      onChange={handleChange}
                      value={values.keyword}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2">商品交期</label>
                  <SearchSelect
                      className={style['form-select-short']}
                      options={orderOptionsInfo?.deliveryType}
                      emptyVal={0}
                      name="deliveryType"
                      setFieldValue={setFieldValue}
                      values={values}
                      style={{ margin: 0 }}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">
                    日期區間
                  </label>
                  <Input
                      type="select"
                      className={style['form-select-short']}
                      name="timeType"
                      onChange={handleChange}
                      value={values.timeType}
                      style={{ margin: 0 }}
                  >
                    <option value="訂購時間">訂購時間</option>
                    <option value="驗退日期">驗退日期</option>
                    <option value="確認時間">配號時間</option>
                    {/* <option value="轉單區間">轉單區間</option> */}
                  </Input>
                  <DatePicker
                      className="form-control"
                      popperClassName={style['form-control-date-picker']}
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.startTime
                            ? moment(values.startTime).toDate()
                            : moment().subtract(7, 'day').toDate()
                      }
                      name="startTime"
                      onChange={date => {
                        setFieldValue('startTime', date);
                      }}
                      selectsStart
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      popperClassName={style['form-control-date-picker']}
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.endTime
                            ? moment(values.endTime).toDate()
                            : moment().toDate()
                      }
                      name="endTime"
                      onChange={date => {
                        setFieldValue('endTime', date);
                      }}
                      selectsEnd
                      minDate={values.startTime}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">編號</label>
                  <Input
                      type="select"
                      className={style['form-select-short']}
                      name="orderNumberType"
                      onChange={handleChange}
                      value={values.orderNumberType}
                      style={{ margin: 0 }}
                  >
                    <option value="配送編號">配送編號</option>
                    <option value="主單編號">主單編號</option>
                    <option value="訂單編號">訂單編號</option>
                  </Input>
                  <Input
                      type="text"
                      className={style['form-input-order-number']}
                      name="orderNumber"
                      onChange={handleChange}
                      value={values.orderNumber}
                  />
                </div>
              </div>
            </form>
            <div className={style.button_position}>
              <Button
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    handleReset();
                  }}
              >
                取消
              </Button>
              <Button
                  type="button"
                  className={style.modal_button_submit}
                  onClick={() => {
                    setFieldValue('page', 1);
                    handleSubmit();
                  }}
              >
                查詢
              </Button>
            </div>
          </div>
        </Card>
        <TableList
            onChangePage={handleChangePage}
            onToggleDiscountModal={toggleDiscountModal}
            onToggleOrderRemarkModal={toggleOrderRemarkModal}
            setOrderInfo={setOrderInfo}
            setCurrentOrderItemId={setCurrentId}
            onToggleDiscountActivityModal={setIsDiscountActivityModalOpen}
        />
        {isDiscountModalOpen && (
            <DiscountDetailModal
                isOpen={isDiscountModalOpen}
                toggle={toggleDiscountModal}
            />
        )}
        {isOrderRemarkModalOpen && orderInfo && (
            <OrderRemarkModal
                isOpen={isOrderRemarkModalOpen}
                toggle={toggleOrderRemarkModal}
                info={orderInfo}
                callBack={handleSubmit}
            />
        )}
        {isDiscountActivityModalOpen && (
            <DiscountActivityDetailModal
                isOpen={isDiscountActivityModalOpen}
                toggle={setIsDiscountActivityModalOpen}
                id={currentId}
            />
        )}
      </Layout>
  );
};
export default CreateLogisticsFailSeven;
