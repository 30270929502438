import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import SearchSelect from 'components/OrderSearchSelector';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import BankListOptions from 'data/bank.json';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Tooltip,
} from 'reactstrap';
import { getOrderList, getOrderOptions } from 'redux/actions/data/orderActions';
import CommonUtil from 'utils/CommonUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';

import { TableHasNoData, TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const PayInfoModal = ({ open, toggle, info }) => {
  return (
      <div className={style.modal}>
        <Modal isOpen={open} zIndex={2000} centered toggle={toggle} size="lg">
          <ModalHeader toggle={toggle} className={style.modal_head}>
            轉帳資訊
          </ModalHeader>
          <ModalBody className={style.modal_body}>
            <FormGroup className="px-2 d-flex">
              <Label style={{ width: '100px' }}>匯款帳號</Label>
              <Row className="flex-grow-1">
                <Col xs={12} md={4} className="mb-2">
                  {info.atmAccBank
                      ? `${info.atmAccBank} ${
                          BankListOptions[info.atmAccBank]?.name || ''
                      }`
                      : '--'}
                </Col>
                <Col xs={12} md={4} className="mb-2">
                  {info.bankBranch || '--'}
                </Col>
                <Col xs={12} md={4} className="mb-2">
                  {info.bankAccountName || '--'}
                </Col>
                <Col xs={12} className="mb-2">
                  {info.atmAccNo || '--'}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup row>
              <Label style={{ width: '100px' }}>匯款金額</Label>
              <Col>
                <div className="price text-dark fw-normal">
                  {(info.discountPrice || 0).toLocaleString('en-US')}
                </div>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter className={style.modal_footer}>
            <Button
                color="primary"
                type="submit"
                className={style.modal_button_submit}
                onClick={toggle}
            >
              關閉
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  );
};
const TooltipItem = ({ target }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  return (
      <Tooltip
          placement="bottom-start"
          isOpen={tooltipOpen}
          target={target}
          toggle={toggleTooltip}
          trigger="click"
      >
        此主單所有退款金額
        <br />
        (包含運費退款)
      </Tooltip>
  );
};
const TableList = ({
                     onChangePage,
                     setIsDiscountActivityModalOpen,
                     setIsDiscountModalOpen,
                     setCurrentDiscountInfo,
                   }) => {
  const { loading, orderListInfo, error } = useSelector(
      state => state.orderList,
  );
  const [payInfoModalOpen, setPayInfoModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});

  const isSticky = orderListInfo?.datas?.length > 0 ? { height: '80vh' } : {};

  return (
      <Card className={style.card}>
        <div className="table-responsive mb-5" style={isSticky}>
          <Table hover bordered striped>
            <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
            <tr>
              <th>
                <div>訂單編號</div>
                <div>退/換貨狀態</div>
              </th>
              <th>
                <div>付款狀態</div>
                <div>付款狀態日期</div>
                <div>退款方式</div>
                <div>退款金額</div>
              </th>
              <th>
                <div>補收單編號</div>
                <div>補收單金額</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>商品單價</div>
                <div>折扣金額</div>
                <div>銷售金額(折扣後)</div>
              </th>
              <th>
                <div>有無商品贈品</div>
                <div>有無活動贈品</div>
                <div>折扣活動總折扣金額</div>
                <div>折價券總折扣金額</div>
                <div>贈品券活動序號</div>
              </th>
              <th>
                <div>會員編號</div>
                <div>會員等級</div>
                <div>收件人</div>
                <div>收件人電話</div>
              </th>
            </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
                <tbody>
                {orderListInfo?.datas?.length > 0 ? (
                    orderListInfo?.datas?.map(i => (
                        <React.Fragment key={JSON.stringify(i)}>
                          <tr className="text-nowrap bg">
                            <td>
                              總金額：
                              <span className="price text-black">
                          {(i.totalAmount || '0')?.toLocaleString('en-US')}
                        </span>
                            </td>
                            <td colSpan="2">主單編號：{i.orderNumber}</td>
                            <td colSpan="2">
                              運費：
                              <span className="price text-black">
                          {i.deliveryCharge?.toLocaleString('en-US')}
                        </span>
                              {i.deliveryChargeReturnNumber && (
                                  <span>
                            （退運費申請 -
                            <span className={style.text_dark_info}>
                              {i.deliveryChargeReturnNumber}
                            </span>
                            )
                          </span>
                              )}
                            </td>
                            <td colSpan={3}>
                              <div className="d-flex">
                                <div className="me-auto fw-bold pe-5 text-danger">
                                  <TooltipItem target={`Tooltip-${i.id}`} />
                                  <AiOutlineInfoCircle id={`Tooltip-${i.id}`} />
                                  退款總金額
                                  <span className="price text-danger">
                              {i.refundAmount?.toLocaleString('en-US')}
                            </span>
                                </div>
                                <FaChevronDown id={`orderId${i.id}`} />
                              </div>
                            </td>
                          </tr>
                          {i.orderItems.map((j, jIndex) => (
                              <tr key={jIndex}>
                                <td className="text-nowrap">
                                  <div>{j.orderItemNumber}</div>
                                  <div>{j.order_RC_Status || '--'}</div>
                                </td>
                                <td>
                                  <div>{j.payStatusName || '--'}</div>
                                  <div>{j.payStatusTime || '--'}</div>
                                  <Button
                                      className={`p-0 text-decoration-none opacity-100 ${
                                          !j.atmAccNo || i.payWay === 1 ? 'text-dark' : ''
                                      }`}
                                      color="link"
                                      disabled={!j.atmAccNo || i.payWay === 1}
                                      onClick={() => {
                                        if (!j.atmAccNo) return;
                                        setPayInfoModalOpen(true);
                                        setCurrentOrder(j);
                                      }}
                                  >
                                    {j.returnWay}
                                  </Button>
                                  {/* 退款金額 */}
                                  <div className="price text-dark fw-normal text-nowrap">
                                    {(
                                        Number(j.finalPrice || 0) -
                                        Number(j.rechargeAmount || 0)
                                    ).toLocaleString('en-US')}
                                  </div>
                                </td>
                                <td>
                                  {j.rechargeList.length > 0
                                      ? j.rechargeList.map(k => (
                                          <div className={style.text_dark_info}>
                                            {k.rechargeNumber}
                                          </div>
                                      ))
                                      : '--'}
                                  <div className="price text-dark fw-normal">
                                    {(j.rechargeAmount || '0').toLocaleString('en-US')}
                                  </div>
                                </td>
                                <td>
                                  {/* 商品名稱 */}
                                  <div>{j.productname || '--'}</div>
                                  {/* 商品選項 */}
                                  <div>
                                    {j.color || '--'}/{j.size || '--'}
                                  </div>
                                  {/* 商品料號 */}
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                      }}
                                      onClick={() => {
                                        sessionStorage.clear();
                                        sessionStorage.setItem('keyword', j.productname);
                                        if (Number(j.productType) === 4) {
                                          window.open(
                                              `/#/GiftManagement?page=1&keyword=${
                                                  j.productname || j.freebieCode
                                              }`,
                                              '_blank',
                                          );
                                        } else if (Number(j.productType) === 1) {
                                          window.open(
                                              `/#/ProductControl?page=1&keyword=${
                                                  j.productname || j.productcode
                                              }`,
                                              '_blank',
                                          );
                                        }
                                      }}
                                  >
                                    {Number(j.productType) === 4
                                        ? j.freebieCode || '--'
                                        : j.productcode || '--'}
                                  </div>
                                  {/* 商品屬性 */}
                                  <div>
                                    {Number(j.productType) === 4
                                        ? j.freebieType || '--'
                                        : '商品'}
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div>{j.quantity}</div>
                                  <div className="price text-black fw-normal">
                                    {j.unitPrice?.toLocaleString('en-US')}
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    -
                                    <div className="price text-black fw-normal">
                                      {(j.discountAmountUnit || '0')?.toLocaleString(
                                          'en-US',
                                      )}
                                    </div>
                                  </div>
                                  <div className="price text-black fw-normal">
                                    {(j.finalPriceUnit || '0')?.toLocaleString('en-US')}
                                  </div>
                                </td>
                                <td className="text-end">
                                  {/* 有無商品贈品 */}
                                  <div>--</div>
                                  {/* 有無活動贈品 */}
                                  <div>--</div>
                                  {/* 折扣活動總折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentDiscountInfo(j.id);
                                        setIsDiscountActivityModalOpen();
                                      }}
                                  >
                                    -
                                    <div
                                        className={`price fw-normal ${style.text_dark_info}`}
                                    >
                                      {(
                                          j.activityDiscountAmountUnit || '0'
                                      )?.toLocaleString('en-US')}
                                    </div>
                                  </div>
                                  {/* 折價券總折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentDiscountInfo(j.id);
                                        setIsDiscountModalOpen();
                                      }}
                                  >
                                    -
                                    <div
                                        className={`price fw-normal ${style.text_dark_info}`}
                                    >
                                      {(
                                          j.couponDiscountAmountUnit || 0
                                      )?.toLocaleString('en-US')}
                                    </div>
                                  </div>
                                  {/* 贈品券活動序號 */}
                                  <div>--</div>
                                </td>
                                <td className="text-nowrap">
                                  <div>{j.memberPhone || '--'}</div>
                                  <div>{j.memberLevel || '--'}</div>
                                  <div>{j.name || '--'}</div>
                                  <div>{j.phone || '--'}</div>
                                </td>
                              </tr>
                          ))}
                        </React.Fragment>
                    ))
                ) : (
                    <TableHasNoData />
                )}
                </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
              current={orderListInfo?.current_page || 1}
              pageSize={orderListInfo?.page_size || 10}
              total={orderListInfo?.total_count || 0}
              onChange={onChangePage}
          />
        </div>
        <PayInfoModal
            open={payInfoModalOpen}
            toggle={() => setPayInfoModalOpen(!payInfoModalOpen)}
            info={currentOrder}
        />
      </Card>
  );
};
const OrderReturnPayList = () => {
  const dispatch = useDispatch();
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
      useToggle();

  const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null);

  const query = useQuery();
  const { location } = history;

  const [currentValues, setCurrentValues] = useState({});
  const { orderOptionsInfo } = useSelector(state => state.orderOptions);
  useEffect(() => {
    dispatch(getOrderOptions());
  }, []);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      endTime: null,
      isRefundOrder: true,
      keyword: '',
      orderNumber: '',
      orderNumberType: '主單編號',
      page: 1,
      page_size: 10,
      startTime: null,
      timeType: '取消 / 退貨完成日期區間',
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
            ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
            ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime)
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        isRefundOrder: true,
        keyword: `${query.value.keyword}`,
        orderNumber: `${query.value.orderNumber}`,
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime)
            : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      dispatch(getOrderList(body));
      setValues(body);
    } else {
      handleReset();
    }
  }, [location.search]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  return (
      <Layout
          pageTitle="退款訂單查詢"
          items={[
            { isActive: false, page_name: '退款訂單管理' },
            { isActive: true, page_name: '退款訂單查詢' },
          ]}
      >
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form>
              <div className="d-flex flex-wrap w-100">
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label text-nowrap mr-2">
                    關鍵字
                  </label>
                  <input
                      type="text"
                      className={`form-control ${style['form-keyword']}`}
                      name="keyword"
                      placeholder="會員電話、收件人、收件人電話"
                      onChange={handleChange}
                      value={values.keyword}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2">付款方式</label>
                  <SearchSelect
                      className={style['form-select-medium']}
                      options={orderOptionsInfo?.payWay}
                      emptyVal={0}
                      name="payWay"
                      setFieldValue={setFieldValue}
                      values={values}
                      style={{ margin: 0 }}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2">付款狀態</label>
                  <SearchSelect
                      className={style['form-select-short']}
                      options={orderOptionsInfo?.payStatus}
                      emptyVal={0}
                      name="orderItemPayStatus"
                      setFieldValue={setFieldValue}
                      values={values}
                      style={{ margin: 0 }}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label text-nowrap mr-2">
                    取消 / 退貨完成日期區間
                  </label>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      popperClassName={style['form-control-date-picker']}
                      showTimeSelect
                      selected={
                        values.startTime
                            ? moment(values.startTime).toDate()
                            : moment().subtract(7, 'day').toDate()
                      }
                      name="startTime"
                      onChange={date => {
                        setFieldValue('startTime', date);
                      }}
                      selectsStart
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      popperClassName={style['form-control-date-picker']}
                      showTimeSelect
                      selected={
                        values.endTime
                            ? moment(values.endTime).toDate()
                            : moment().toDate()
                      }
                      name="endTime"
                      onChange={date => {
                        setFieldValue('endTime', date);
                      }}
                      selectsEnd
                      minDate={values.startTime}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">編號</label>
                  <Input
                      type="select"
                      className={style['form-select-medium']}
                      name="orderNumberType"
                      onChange={handleChange}
                      value={values.orderNumberType}
                      style={{ margin: 0 }}
                  >
                    <option value="主單編號">主單編號</option>
                    <option value="訂單編號">訂單編號</option>
                    <option value="退貨單號">退貨單編號</option>
                  </Input>
                  <Input
                      type="text"
                      className={style['form-input-order-number']}
                      name="orderNumber"
                      onChange={handleChange}
                      value={values.orderNumber}
                  />
                </div>
              </div>
            </form>
            <div className={style.button_position}>
              <Button
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    handleReset();
                  }}
              >
                取消
              </Button>
              <Button
                  type="button"
                  className={style.modal_button_submit}
                  onClick={() => {
                    setFieldValue('page', 1);
                    handleSubmit();
                  }}
              >
                查詢
              </Button>
            </div>
          </div>
        </Card>
        <TableList
            onChangePage={handleChangePage}
            setIsDiscountActivityModalOpen={setIsDiscountActivityModalOpen}
            setIsDiscountModalOpen={setIsDiscountModalOpen}
            setCurrentDiscountInfo={setCurrentDiscountInfo}
        />
        {isDiscountModalOpen && (
            <DiscountDetailModal
                isOpen={isDiscountModalOpen}
                toggle={setIsDiscountModalOpen}
                id={currentDiscountInfo}
            />
        )}
        {isDiscountActivityModalOpen && (
            <DiscountActivityDetailModal
                isOpen={isDiscountActivityModalOpen}
                toggle={setIsDiscountActivityModalOpen}
                id={currentDiscountInfo}
            />
        )}
      </Layout>
  );
};
export default OrderReturnPayList;
