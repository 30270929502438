import Layout from 'components/Layout';
import { BlockSpinner } from 'components/Spinner';
import BankListOptions from 'data/bank.json';
import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Table,
} from 'reactstrap';
import { getBankList } from 'redux/actions/data/bankCodeAction';
import { getOrderList } from 'redux/actions/data/orderActions';
import {
  addReturnOrder,
  checkReturnOrderItemPair,
  getOrderitemReturnInfo,
} from 'redux/actions/data/orderReturnActions';
import MessageUtil from 'utils/MessageUtil';
import useFetch from 'utils/useFetch';
import * as Yup from 'yup';

import { TableHasNoData, TableLoading } from '../components/TableHasNoData';
import { baseURL, prefix_api_path } from '../redux/actions/data/api_config';
import { orderReturnReasonList } from '../redux/actions/data/orderReturnReasonActions';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const TableList = ({
  list,
  flatOrderItems,
  handleCheckNotReturnable,
  onChangePage,
  onAction,
}) => {
  const { loading, orderListInfo, error } = useSelector(
    state => state.orderList,
  );
  const { orderReturnReasonListInfo } = useSelector(
    state => state.orderReturnReasonList,
  );
  const dispatch = useDispatch();

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleReset,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: '',
      atmAccBank: '',
      atmAccNo: '',
      bankAccountName: '',
      bankBranch: '',
      cityId: 1,
      countryId: 1,
      name: '',
      orderItems: [],
      orderMasterId: '',
      payWayName:
        orderListInfo?.datas?.length > 0
          ? orderListInfo?.datas[0].payWayName
          : '',
      phone: '',
      reason: '',
      returnDesc: '',
      townId: 1,
    },
    async onSubmit(formModel) {
      if (formModel.orderItems?.length > 0) {
        if (onAction) {
          await onAction(
            {
              ...formModel,
              cityId: +formModel.cityId,
              orderItems: formModel.orderItems.map(o => {
                return {
                  ...o,
                  returnReason: formModel.reason,
                };
              }),
              returnReason: formModel.reason,
              townId: +formModel.townId,
            },
            handleReset,
          );
        }
      }
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().required('必填'),
      atmAccBank: Yup.string().when('payWayName', {
        is: val => val === 'ATM轉帳',
        then: Yup.string().required('必填'),
      }),
      atmAccNo: Yup.string().when('payWayName', {
        is: val => val === 'ATM轉帳',
        then: Yup.string().required('必填'),
      }),
      bankAccountName: Yup.string().when('payWayName', {
        is: val => val === 'ATM轉帳',
        then: Yup.string().required('必填'),
      }),
      bankBranch: Yup.string().when('payWayName', {
        is: val => val === 'ATM轉帳',
        then: Yup.string().required('必填'),
      }),
      name: Yup.string().required('必填'),
      orderItems: Yup.array().min(1, '必填'),
      payWayName: Yup.string(),
      phone: Yup.string().required('必填'),
      reason: Yup.string().required('必填'),
      returnDesc: Yup.string().required('必填'),
    }),
  });

  const { data: cityList } = useFetch(
    `${baseURL + prefix_api_path}/Website/City/cityList/1`,
  );

  const { data: townList } = useFetch(
    `${baseURL + prefix_api_path}/Website/City/townList/${values.cityId}`,
  );

  const { info } = useSelector(state => state.getOrderitemReturnInfo);

  useEffect(() => {
    if (list?.length) {
      list.forEach(l => {
        setFieldValue('orderMasterId', l.id);
      });
    }
  }, [list]);

  const handleCheckEvent = useCallback(
    async (orderItem, isChecked) => {
      try {
        const res = await checkReturnOrderItemPair({
          items: [
            ...values.orderItems.filter(i => i.orderItemId !== orderItem.id),
            {
              ...orderItem,
              freebieRelatedNumbers: orderItem.freebieRelatedNumbers,
              isSelected: isChecked,
              orderItemId: orderItem.id,
              productId: orderItem.productId,
              productType: orderItem.productType,
              quantity: orderItem.quantity,
              returnReason: '',
              unitPrice: orderItem.unitPrice,
            },
          ].map(o => {
            return {
              id: o.orderItemId || o.id,
              isSelected: o.isSelected,
              returnQty: o.quantity,
            };
          }),
          orderMasterId: orderItem.orderMasterId,
          orderNumber: orderItem.orderItemNumber,
        });

        if (res?.result?.outputItems) {
          const itemMap = {};
          res.result.outputItems.forEach(i => {
            itemMap[i.id] = {
              ...values?.orderItems?.find(o => o.orderItemId === i.id),
              ...i,
            };

            if (
              i.id !== orderItem.id &&
              !values.orderItems.find(o => o.id === i.id)
            ) {
              itemMap[i.id].isSelected = i.isSelected;
              itemMap[i.id].quantity = i.returnQty;
              itemMap[i.id].appendId = orderItem.id;
              itemMap[
                i.id
              ].message = `與${orderItem.orderItemNumber}同折扣活動，須整組退貨`;
              itemMap[i.id].disabled = true;
            }
          });

          const checkItems = flatOrderItems
            .filter(l => {
              return (
                itemMap[l.orderItemId] && itemMap[l.orderItemId].isSelected
              );
            })
            .map(l => {
              return {
                ...l,
                ...itemMap[l.orderItemId],
              };
            });
          setFieldValue('orderItems', [...checkItems]);
        }
      } catch (e) {}
    },
    [values?.orderItems, flatOrderItems],
  );

  useEffect(() => {
    let body = values.orderItems.map(item => ({
      num: item.quantity,
      orderItemId: item.orderItemId,
    }));
    if (body?.length > 0) {
      dispatch(getOrderitemReturnInfo(body));
    }
  }, [values.orderItems]);

  return (
    <>
      <Card className={`${style.card} p-0 mb-5`}>
        <CardHeader className={style.card_header_info}>
          1、勾選欲代替消費者辦理退貨的商品
        </CardHeader>
        <CardBody>
          {loading && isSubmitting && <BlockSpinner />}
          <div
            className={`${style.table_head} p-3 d-flex align-items-center gap-4`}
          >
            <div className="d-flex align-items-center gap-1">
              <input
                className={style.maintain_list_checkbox}
                type="checkbox"
                checked={
                  values.orderItems.length > 0 &&
                  values.orderItems.length ===
                    flatOrderItems.filter(i => !i.notReturnable)?.length
                }
                onChange={e => {
                  const val = e.target.checked;
                  setFieldValue(
                    'orderItems',
                    val ? flatOrderItems.filter(i => !i.notReturnable) : [],
                  );
                }}
              />
              本頁全選
            </div>
          </div>
          <div className="table-responsive mb-5">
            <Table hover bordered striped>
              <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
              >
                <tr>
                  <th>選擇</th>
                  <th>申請退貨原因</th>
                  <th>退貨數量</th>
                  <th>
                    <div>訂單編號</div>
                    <div>退換貨狀態</div>
                  </th>
                  <th>
                    <div>商品名稱</div>
                    <div>商品選項</div>
                  </th>
                  <th>
                    <div>商品料號</div>
                    <div>商品屬性</div>
                  </th>
                  <th>
                    <div>數量</div>
                    <div>商品單價</div>
                    <div>折扣金額</div>
                    <div>銷售金額(折扣後)</div>
                  </th>
                  <th>
                    <div>實際出貨數量</div>
                    <div>實際出貨金額</div>
                  </th>
                  <th>
                    <div>有無商品贈品</div>
                    <div>有無活動贈品</div>
                    <div>折扣活動總折扣金額</div>
                    <div>折價券總折扣金額</div>
                    <div>贈品券活動序號</div>
                  </th>
                  <th>
                    <div>配送方式</div>
                    <div>配送商</div>
                  </th>
                  <th>轉單時間</th>
                  <th>
                    <div>付款方式</div>
                    <div>繳費期限</div>
                    <div>預計出貨日</div>
                    <div>鑑賞期起算日</div>
                  </th>
                  <th>
                    <div>會員編號</div>
                    <div>會員等級</div>
                    <div>收件人</div>
                    <div>收件人電話</div>
                  </th>
                </tr>
              </thead>
              {loading && <TableLoading />}
              {!loading && (
                <tbody>
                  {list?.map(i => (
                    <React.Fragment key={JSON.stringify(i)}>
                      <tr className="text-nowrap bg">
                        <td></td>
                        <td className="price text-black">
                          總金額：
                          {(i.totalAmount || '0').toLocaleString('en-US')}
                        </td>
                        <td colSpan="2">主單編號：{i.orderNumber}</td>
                        <td colSpan="2">
                          運費：
                          <span className="price text-black">
                            {i.deliveryCharge?.toLocaleString('en-US')}
                          </span>
                        </td>
                        <td colSpan={7} className="text-end">
                          <FaChevronDown id={`orderId${i.id}`} />
                        </td>
                      </tr>
                      {i.orderItems.map((j, jIndex) => {
                        return (
                          <tr key={jIndex}>
                            <td className="align-top">
                              <input
                                className={style.maintain_list_checkbox}
                                type="checkbox"
                                disabled={true}
                                onChange={e => {
                                  if (
                                    values.orderItems?.some(
                                      k => k.orderItemId === j.id,
                                    )
                                  ) {
                                    handleCheckEvent(
                                      {
                                        ...j,
                                        quantity: 1,
                                      },
                                      false,
                                    );
                                  } else {
                                    handleCheckEvent(
                                      {
                                        ...j,
                                        quantity: 1,
                                      },
                                      true,
                                    );
                                  }
                                }}
                                checked={
                                  values.orderItems
                                    .map(k => k.orderItemId)
                                    .indexOf(j.id) !== -1 ||
                                  values.freebieRelatedNumbers?.find(f =>
                                    j.freebieRelatedNumbers?.find(
                                      jf => jf === f,
                                    ),
                                  )
                                }
                              />
                              <div className="d-none">{j.id}</div>
                            </td>
                            <td className="align-top">
                              {j.productType != 4 && (
                                <Input
                                  className="mb-2"
                                  type="select"
                                  value={
                                    values.orderItems?.find(
                                      k => k.orderItemId === j.id,
                                    )?.returnReason || ''
                                  }
                                  disabled={true}
                                  onChange={e => {
                                    const isExisting = values.orderItems.filter(
                                      item => item.orderItemId === j.id,
                                    );
                                    setFieldValue(
                                      'orderItems',
                                      isExisting.length > 0
                                        ? values.orderItems.map(mlist => {
                                            if (mlist.orderItemId === j.id) {
                                              mlist.returnReason =
                                                e.target.value;
                                            }
                                            return mlist;
                                          })
                                        : [
                                            ...values.orderItems,
                                            {
                                              orderItemId: j.id,
                                              productId: j.productId,
                                              productType: j.productType,
                                              quantity: 1,
                                              returnReason: e.target.value,
                                              unitPrice: j.unitPrice,
                                            },
                                          ],
                                    );
                                  }}
                                >
                                  <option value="" disabled>
                                    請選擇
                                  </option>
                                  {orderReturnReasonListInfo?.datas?.map(i => (
                                    <option value={i.reason} key={i.id}>
                                      {i.reason}
                                    </option>
                                  ))}
                                </Input>
                              )}
                              {handleCheckNotReturnable(j.orderItemStatus) && (
                                <FormText color="danger">不能退貨</FormText>
                              )}
                              {values.orderItems?.find(
                                k => k.orderItemId === j.id,
                              ) &&
                                touched.orderItems?.[
                                  values.orderItems
                                    .map(k => k.orderItemId)
                                    .indexOf(j.id)
                                ] &&
                                !values.orderItems?.find(
                                  k => k.orderItemId === j.id,
                                )?.returnReason && (
                                  <FormText color="danger" className="d-block">
                                    必填
                                  </FormText>
                                )}
                            </td>
                            <td className="align-top">
                              {j.productType != 4 && (
                                <Input
                                  className="mb-1"
                                  type="select"
                                  value={
                                    values.orderItems?.find(
                                      k => k.orderItemId === j.id,
                                    )?.quantity || 1
                                  }
                                  disabled={true}
                                  onChange={e => {
                                    setFieldValue(
                                      'orderItems',
                                      values.orderItems.find(
                                        item => item.orderItemId === j.id,
                                      )
                                        ? values.orderItems.map(mlist => {
                                            if (mlist.orderItemId === j.id) {
                                              mlist.quantity = Number(
                                                e.target.value,
                                              );
                                            }
                                            return mlist;
                                          })
                                        : [
                                            ...values.orderItems,
                                            {
                                              orderItemId: j.id,
                                              quantity: Number(e.target.value),
                                              returnReason: '',
                                            },
                                          ],
                                    );
                                    handleCheckEvent(
                                      {
                                        ...j,
                                        quantity: Number(e.target.value),
                                      },
                                      true,
                                    );
                                  }}
                                >
                                  {Array.from(
                                    { length: j.quantity },
                                    (_, qIndex) => qIndex + 1,
                                  ).map(k => (
                                    <option
                                      value={k}
                                      key={k}
                                      className="text-center"
                                    >
                                      {k}
                                    </option>
                                  ))}
                                </Input>
                              )}

                              {j.productType === 4 && (
                                <div className="text-center">{j.quantity}</div>
                              )}
                            </td>
                            <td>
                              <div className="text-info text-nowrap">
                                {j.orderItemNumber}
                              </div>
                              <div>{j.order_RC_Status || '--'}</div>
                            </td>
                            <td>
                              <div>{j.productname || '--'}</div>
                              <div>
                                {j.color || '--'}/{j.size || '--'}
                              </div>
                              <div className="form-error text-nowrap">
                                {values.orderItems?.find(
                                  k => k.orderItemId === j.id,
                                )?.message || ''}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  color: '#0077ff',
                                  cursor: 'pointer',
                                  fontSize: '16px',
                                }}
                                onClick={() => {
                                  sessionStorage.clear();
                                  sessionStorage.setItem(
                                    'keyword',
                                    j.productname,
                                  );
                                  if (Number(j.productType) === 4) {
                                    window.open(
                                      `/#/GiftManagement?page=1&keyword=${
                                        j.productname || j.freebieCode
                                      }`,
                                      '_blank',
                                    );
                                  } else if (Number(j.productType) === 1) {
                                    window.open(
                                      `/#/ProductControl?page=1&keyword=${
                                        j.productname || j.productcode
                                      }`,
                                      '_blank',
                                    );
                                  }
                                }}
                              >
                                {Number(j.productType) === 4
                                  ? j.freebieCode || '--'
                                  : j.productcode || '--'}
                              </div>
                              {/* 商品屬性 */}
                              <div>
                                {Number(j.productType) === 4
                                  ? j.freebieType || '--'
                                  : '商品'}
                              </div>
                            </td>
                            <td className="text-end">
                              {/* 數量 */}
                              <div>{j.quantity || '--'}</div>
                              {/* 商品單價 */}
                              <div className="price text-black fw-normal">
                                {j.unitPrice.toLocaleString('en-US')}
                              </div>
                              {/* 折扣金額 */}
                              <div
                                className="d-flex justify-content-end text-primary"
                                role="button"
                                onClick={() => {
                                  modal.showBillInfo({
                                    ...j,
                                    orderNumber: i.orderNumber,
                                  });
                                }}
                              >
                                -
                                <div className="price text-black fw-normal">
                                  {j?.discountAmountUnit?.toLocaleString(
                                    'en-US',
                                  ) || 0}
                                </div>
                              </div>
                              {/* 銷售金額(折扣後) */}
                              <div className="price text-black fw-normal">
                                {j?.finalPriceUnit?.toLocaleString('en-US') ||
                                  0}
                              </div>
                            </td>
                            <td className="text-center">
                              {/* 實際出貨數量 */}
                              <div>{j.quantity}</div>
                              {/* 實際出貨金額 */}
                              <div className="price text-black fw-normal">
                                {j.finalPrice?.toLocaleString('en-US')}
                              </div>
                            </td>
                            <td className="text-end">
                              <div>{j.isThereFreebie_P ? '有' : '無'}</div>
                              <div>{j.isThereFreebie_A ? '有' : '無'}</div>
                              {/* 折扣活動總折扣金額 */}
                              <div className="d-flex justify-content-end">
                                -
                                <div className="price fw-normal text-black">
                                  {(
                                    j.activityDiscountAmountUnit || '0'
                                  )?.toLocaleString('en-US')}
                                </div>
                              </div>
                              {/* 折價券總折扣金額 */}
                              <div className="d-flex justify-content-end">
                                -
                                <div className="price fw-normal text-black">
                                  {(
                                    j.couponDiscountAmountUnit || 0
                                  )?.toLocaleString('en-US')}
                                </div>
                              </div>
                              {/* 贈品券活動序號 */}
                              <div>--</div>
                            </td>
                            <td>
                              <div>{j.logisticsWayName || '--'}</div>
                              <div>{j.returnHomeDeliveryCName || '--'}</div>
                            </td>
                            <td>{i.orderTime}</td>
                            <td>
                              <div>{i.payWayName}</div>
                              <div>{j.expireDate_ATM || '--'}</div>
                              <div>{j.expectedDeliveryDate || '--'}</div>
                              <div>{j.freeReturnDateStart || '--'}</div>
                            </td>
                            <td>
                              <div>{j.memberPhone || '--'}</div>
                              <div>{j.memberLevel || '--'}</div>
                              <div>{j.name || '--'}</div>
                              <div>{j.phone || '--'}</div>
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  ))}
                  {(!list?.length || error) && <TableHasNoData />}
                </tbody>
              )}
            </Table>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              current={orderListInfo?.current_page || 1}
              pageSize={orderListInfo?.page_size || 10}
              total={orderListInfo?.total_count || 0}
              onChange={onChangePage}
            />
          </div>
          {values?.orderItems?.length > 0 && flatOrderItems?.filter(i => !i.notReturnable)?.length > 0 && (
            <FormGroup>
              <Label style={{ width: 150 }}>
                <span className="text-danger">* </span>
                申請退貨原因
              </Label>
              <Input
                className="mb-2"
                type="select"
                value={values.reason}
                onChange={e => {
                  setFieldValue('reason', e.target.value);
                }}
              >
                <option value="" disabled>
                  請選擇
                </option>
                {orderReturnReasonListInfo?.datas?.map(i => (
                  <option value={i.reason} key={i.id}>
                    {i.reason}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}
          {values?.orderItems?.length > 0 && flatOrderItems?.filter(i => !i.notReturnable)?.length > 0 && (
            <FormGroup>
              <Label style={{ width: 150 }}>
                <span className="text-danger">* </span>
                退貨描述
              </Label>
              <Input
                type="textarea"
                rows={5}
                name="returnDesc"
                id="returnDesc"
                onChange={handleChange}
                value={values.returnDesc}
              />
              {errors.returnDesc && touched.returnDesc && (
                <FormText color="danger" className="d-block">
                  {errors.returnDesc}
                </FormText>
              )}
            </FormGroup>
          )}
        </CardBody>
      </Card>
      {values?.orderItems?.length > 0 && flatOrderItems?.filter(i => !i.notReturnable)?.length > 0 && (
        <>
          <Card className={`${style.card} p-0 mb-5`}>
            <CardHeader className={style.card_header_info}>
              2、退貨資訊
            </CardHeader>
            <CardBody>
              <FormGroup row>
                <Label style={{ width: 150 }}>
                  <span className="text-danger">* </span>聯絡人姓名
                </Label>
                <Col>
                  <Input
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                  />
                  {errors.name && touched.name && (
                    <FormText color="danger" className="d-block">
                      {errors.name}
                    </FormText>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label style={{ width: 150 }}>
                  <span className="text-danger">* </span>手機號碼
                </Label>
                <Col>
                  <Input
                    name="phone"
                    onChange={handleChange}
                    value={values.phone}
                  />
                  {errors.phone && touched.phone && (
                    <FormText color="danger" className="d-block">
                      {errors.phone}
                    </FormText>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label style={{ width: 150 }}>
                  <span className="text-danger">* </span>聯絡地址
                </Label>
                <Col>
                  <div className="d-flex gap-2">
                    <Input
                      type="select"
                      name="cityId"
                      onChange={handleChange}
                      value={values.cityId}
                    >
                      {cityList?.map(i => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                    </Input>
                    <Input
                      type="select"
                      name="townId"
                      onChange={handleChange}
                      value={values.townId}
                    >
                      {townList?.map(i => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <Input
                    className="mt-2"
                    placeholder="輸入詳細地址"
                    name="address"
                    onChange={handleChange}
                    value={values.address}
                  />
                  {errors.address && touched.address && (
                    <FormText color="danger" className="d-block">
                      {errors.address}
                    </FormText>
                  )}
                </Col>
              </FormGroup>
            </CardBody>
          </Card>
          {values?.orderItems?.length > 0 && info?.returnWay && (
            <Card className={`${style.card} p-0 mb-5`}>
              <CardHeader className={style.card_header_info}>
                3、退款資訊
              </CardHeader>
              <CardBody>
                <FormGroup row>
                  <Label style={{ width: 150 }}>退款方式</Label>
                  <Col>{info.returnWay}</Col>
                </FormGroup>
                {info?.returnWay === 'ATM轉帳退款' && (
                  <FormGroup row>
                    <Label style={{ width: 150 }}>
                      <span className="text-danger">* </span>匯款帳號
                    </Label>
                    <Col className="row gy-2">
                      <Col xs={12} md={4}>
                        <Input
                          type="select"
                          name="atmAccBank"
                          onChange={handleChange}
                          value={values.atmAccBank}
                        >
                          <option value="" disabled>
                            選擇銀行
                          </option>
                          {Object.keys(BankListOptions)
                            .sort()
                            .map(i => (
                              <option value={i} key={i}>
                                {i} {BankListOptions[i].name}
                              </option>
                            ))}
                        </Input>
                        {errors.atmAccBank && touched.atmAccBank && (
                          <FormText color="danger" className="d-block">
                            {errors.atmAccBank}
                          </FormText>
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          type="text"
                          placeholder="請填寫分行代號"
                          name="bankBranch"
                          onChange={handleChange}
                          value={values.bankBranch}
                        />
                        {errors.bankBranch && touched.bankBranch && (
                          <FormText color="danger" className="d-block">
                            {errors.bankBranch}
                          </FormText>
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          type="text"
                          placeholder="請填寫戶名"
                          name="bankAccountName"
                          onChange={handleChange}
                          value={values.bankAccountName}
                        />
                        {errors.bankAccountName && touched.bankAccountName && (
                          <FormText color="danger" className="d-block">
                            {errors.bankAccountName}
                          </FormText>
                        )}
                      </Col>
                      <Col xs={12}>
                        <Input
                          type="text"
                          placeholder="請填寫完整帳號"
                          name="atmAccNo"
                          onChange={handleChange}
                          value={values.atmAccNo}
                        />
                        {errors.atmAccNo && touched.atmAccNo && (
                          <FormText color="danger" className="d-block">
                            {errors.atmAccNo}
                          </FormText>
                        )}
                      </Col>
                    </Col>
                  </FormGroup>
                )}
                {info?.returnWay === '信用卡退款' && (
                  <FormGroup row>
                    <Label style={{ width: 150 }}>發卡銀行</Label>
                    <Col>{info.bank}</Col>
                  </FormGroup>
                )}
              </CardBody>
            </Card>
          )}
          <Button
            type="submit"
            className={style.modal_button_submit}
            onClick={handleSubmit}
            disabled={
              values.orderItems.length <= 0 || Object.keys(errors).length > 0
            }
          >
            確認退貨
          </Button>
        </>
      )}
    </>
  );
};
const OrderReturnCreate = () => {
  const dispatch = useDispatch();
  const { orderListInfo } = useSelector(state => state.orderList);
  const [pageIsChanged, setPageIsChanged] = useState(false);
  const [showTable, setShowTable] = useState(false);
  useEffect(() => {
    dispatch(
      orderReturnReasonList(
        {
          page: 1,
          page_size: 10,
        },
        '退貨',
      ),
    );
  }, []);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    touched,
    errors,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      isOktoReturn: true,
      orderNumber: '',
      orderNumberType: '主單編號',
      page: 1,
      page_size: 10,
    },
    onReset: () => {
      setShowTable(false);
    },
    async onSubmit(formModel) {
      setShowTable(false);
      const params = {
        ...formModel,
        page: pageIsChanged ? formModel.page : 1,
      };
      dispatch(getOrderList(params));
      setShowTable(true);
      setPageIsChanged(false);
    },
    validationSchema: Yup.object().shape({
      orderNumber: Yup.string().required('必填'),
    }),
  });

  const handleCheckNotReturnable = orderItemStatus => {
    return orderItemStatus !== 4 && orderItemStatus !== 7;
  };

  const flatOrderItems = useMemo(() => {
    return (
      orderListInfo?.datas
        ?.map(i =>
          i.orderItems.map(j => ({
            freebieRelatedNumbers: j.freebieRelatedNumbers,
            notReturnable: handleCheckNotReturnable(j.orderItemStatus),
            orderItemId: j.id,
            productId: j.productId,
            productType: j.productType,
            quantity: 1,
            returnReason: '',
            unitPrice: j.unitPrice,
          })),
        )
        .flat() || []
    );
  }, [orderListInfo]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    setPageIsChanged(true);
    handleSubmit();
  };

  const handleAction = useCallback((formModel, cb) => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        try {
          await addReturnOrder(formModel);
          MessageUtil.toastSuccess('儲存成功！');
        } catch (e) {
          MessageUtil.alertWanring('申請失敗', e);
        }
        handleSubmit();
        cb();
      },
      title: '確認申請退貨？',
    });
  }, []);

  useEffect(() => {
    dispatch(getBankList());
  }, []);

  return (
    <Layout
      pageTitle="新增退貨單"
      items={[
        { isActive: true, page_name: '退貨訂單管理' },
        { isActive: false, page_name: '新增退貨單' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />
          <span className={style.fs_14}>
            可以退貨的訂單：當主單狀態是【已完成】，訂單狀態不是【退貨處理中】、【換貨處理中】、【已退貨】時，才可以退貨。
          </span>
        </div>
        <div>
          <AiOutlineInfoCircle />
          <span className={style.fs_14}>
            確認退貨後，即會進入退款流程，退貨前請務必確認。
          </span>
        </div>
      </Alert>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className="d-flex flex-wrap w-100">
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">編號</label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  name="orderNumberType"
                  onChange={handleChange}
                  value={values.orderNumberType}
                  style={{ margin: 0 }}
                >
                  <option value="主單編號">主單編號</option>
                  <option value="訂單編號">訂單編號</option>
                </Input>
                <Input
                  type="text"
                  className={style['form-input-order-number']}
                  name="orderNumber"
                  onChange={handleChange}
                  value={values.orderNumber}
                  invalid={touched.orderNumber && errors.orderNumber}
                />
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      {showTable && (
        <TableList
          onChangePage={handleChangePage}
          onAction={handleAction}
          list={orderListInfo?.datas}
          orderListInfo={orderListInfo}
          flatOrderItems={flatOrderItems}
          handleCheckNotReturnable={handleCheckNotReturnable}
        />
      )}
    </Layout>
  );
};
export default OrderReturnCreate;
