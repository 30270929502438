import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import { getOrderOptions } from 'redux/actions/data/orderActions';

import style from '../../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

const ShoppingRecords = ({ orderList, currentPage }) => {
  const dispatch = useDispatch();
  const currentData = orderList?.filter((order, index) => {
    if (
        index >= 10 * (currentPage - 1) &&
        index <= 9 + (currentPage - 1) * 10
    ) {
      return order;
    }
  });

  const { orderOptionsInfo } = useSelector(state => state.orderOptions);

  useEffect(() => {
    dispatch(getOrderOptions());
  }, []);

  return (
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>主單編號</th>
            <th>訂單編號</th>
            <th>訂單狀態</th>
            <th>退貨狀態</th>
            <th>商品名稱</th>
            <th>商品序號</th>
            <th>銷售金額</th>
            <th>轉單時間</th>
            <th>
              <div>門市取貨時間/</div>
              <div>宅配出貨時間/</div>
              <div>超取取貨時間</div>
            </th>
          </tr>
          </thead>
          {!orderList && <TableLoading />}
          {orderList && (
              <tbody>
              {currentData?.length > 0 ? (
                  currentData.map((order, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          {/* 主單編號 */}
                          {order.orderNumber}
                        </td>
                        <td className="text-center">
                          {/* 訂單編號 */}
                          {order.orderItemNumber}
                        </td>
                        <td className="text-center">
                          {/* 訂單狀態 */}
                          {
                            orderOptionsInfo?.orderStatus?.find(
                                option => option.code === order.orderStatus,
                            )?.name
                          }
                        </td>
                        <td className="text-center">
                          {/* 退貨狀態 */}
                          {orderOptionsInfo?.returnStatus?.find(
                              option => option.code === order.returnStatus,
                          )?.name || '--'}
                        </td>
                        <td className="text-center">
                          {/* 商品名稱 */}
                          {order.productname || '--'}
                        </td>
                        <td className="text-center">
                          {/* 贈品序號 */}
                          {order.productcode || '--'}
                        </td>
                        <td className="text-center price text-black fw-normal">
                          {/* 銷售金額 */}
                          {(order.finalPrice || '0').toLocaleString('en-US')}
                        </td>
                        <td className="text-center">
                          {/* 轉單時間 */}
                          {order.orderTime
                              ? moment(order.orderTime).format('YYYY/MM/DD HH:mm:ss')
                              : '--'}
                        </td>
                        <td className="text-center">
                          {/* 門市取貨時間/宅配出貨時間/超取取貨時間 */}
                          <div>
                            {order.storePickingupTime
                                ? moment(order.storePickingupTime).format(
                                    'YYYY/MM/DD HH:mm:ss',
                                )
                                : '--'}
                          </div>
                          <div>
                            {order.homeShippingTime
                                ? moment(order.homeShippingTime).format(
                                    'YYYY/MM/DD HH:mm:ss',
                                )
                                : '--'}
                          </div>
                          <div>
                            {order.cStorePickingupTime
                                ? moment(order.cStorePickingupTime).format(
                                    'YYYY/MM/DD HH:mm:ss',
                                )
                                : '--'}
                          </div>
                        </td>
                      </tr>
                  ))
              ) : (
                  <TableHasNoData />
              )}
              </tbody>
          )}
        </Table>
      </div>
  );
};

export default ShoppingRecords;
