import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import OrderReturnDescModal from 'components/OrderReturnDescModal';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect } from 'react';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Input, Table } from 'reactstrap';
import { getOrderList } from 'redux/actions/data/orderActions';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import request from 'utils/useAxios';
import useQuery from 'utils/useQuery';

import Loader from '../components/Loader';
import TableHasNoData from '../components/TableHasNoData';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const TableList = ({
                     onAction,
                     onReject,
                     onChangePage,
                     setIsDiscountActivityModalOpen,
                     setIsDiscountModalOpen,
                     setCurrentDiscountInfo,
                   }) => {
  const { loading, orderListInfo } = useSelector(state => state.orderList);

  const { values, setFieldValue, handleReset } = useFormik({
    enableReinitialize: true,
    initialValues: {
      list: [],
    },
  });
  const handleSubmit = type => {
    type === 1 && onAction && onAction(values, handleReset);
    type === 2 && onReject && onReject(values, handleReset);
  };

  const isSticky = orderListInfo?.datas?.length > 0 ? { height: '80vh' } : {};
  const [descOpen, setDescOpen] = useState(false);
  const [currentDescInfo, setCurrentDescInfo] = useState(null);
  const handleToggleDesc = () => {
    setDescOpen(!descOpen);
  };
  return (
      <Card className={style.card}>
        <div className="mb-3">
          <Button
              className="me-2"
              color="success"
              onClick={() => handleSubmit(1)}
              disabled={values.list.length <= 0}
          >
            物流取件成功
          </Button>
          <Button
              color="success"
              disabled={values.list.length <= 0}
              onClick={() => handleSubmit(2)}
          >
            物流取件失敗
          </Button>
        </div>
        <div
            className={`${style.table_head} p-3 d-flex align-items-center gap-4`}
        >
          <div className="d-flex align-items-center gap-1">
            <input
                className={style.maintain_list_checkbox}
                type="checkbox"
                checked={
                    values.list.length > 0 &&
                    values.list.length ===
                    orderListInfo?.datas?.reduce(
                        (a, b) => a + b.orderItems.length,
                        0,
                    )
                }
                name="all"
                onChange={e => {
                  const val = e.target.checked;
                  setFieldValue(
                      'list',
                      val
                          ? orderListInfo?.datas
                              ?.map(i => i.orderItems.map(j => j.id))
                              .flat()
                          : [],
                  );
                }}
            />{' '}
            本頁全選
          </div>
        </div>
        <div className="table-responsive" style={isSticky}>
          <Table hover bordered striped className="mb-5">
            <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
            <tr>
              <th>選擇</th>
              <th>
                <div>訂單編號</div>
                <div>換貨單號</div>
              </th>
              <th>
                <div>配送商</div>
                <div>配送編號</div>
              </th>

              <th>
                <div>換貨單狀態</div>
                <div>換貨成立時間</div>
                <div>換貨狀態時間</div>
              </th>
              <th>
                <div>換貨原因</div>
                <div>換貨描述</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>商品單價</div>
                <div>折扣金額</div>
                <div>銷售金額(折扣後)</div>
              </th>
              <th>
                <div>有無商品贈品</div>
                <div>有無活動贈品</div>
                <div>折扣活動總折扣金額</div>
                <div>折價券總折扣金額</div>
                <div>贈品券活動序號</div>
              </th>
              <th>
                <div>會員編號</div>
                <div>會員等級</div>
                <div>換貨聯絡人</div>
                <div>換貨聯絡人電話</div>
              </th>
              <th>
                <div>換貨國家</div>
                <div>換貨郵遞區號</div>
                <div>換貨城市</div>
                <div>換貨鄉鎮市區</div>
                <div>換貨地址</div>
              </th>
            </tr>
            </thead>
            {loading && (
                <tbody>
                <tr>
                  <td colSpan="100%">
                    <Loader />
                  </td>
                </tr>
                </tbody>
            )}
            {!loading && (
                <tbody>
                {orderListInfo?.datas?.length > 0 ? (
                    orderListInfo?.datas?.map(i => (
                        <React.Fragment key={JSON.stringify(i)}>
                          <tr className="text-nowrap bg">
                            <td>
                              <input
                                  className={style.maintain_list_checkbox}
                                  type="checkbox"
                                  onChange={e => {
                                    const val = e.target.checked;
                                    setFieldValue(
                                        'list',
                                        val
                                            ? [
                                              ...new Set([
                                                ...values.list,
                                                ...i.orderItems.map(j => j.id),
                                              ]),
                                            ]
                                            : values.list.filter(
                                                j =>
                                                    i.orderItems.map(k => k.id).indexOf(j) ===
                                                    -1,
                                            ),
                                    );
                                  }}
                                  checked={i.orderItems
                                      .map(j => j.id)
                                      .every(j => values.list.indexOf(j) !== -1)}
                              />
                            </td>
                            <td>
                              總金額：
                              <span className="price text-black">
                          {(i.totalAmount || '0').toLocaleString('en-US')}
                        </span>
                            </td>
                            <td colSpan="2">主單編號：{i.orderNumber}</td>
                            <td colSpan="2">
                              運費：
                              <span className="price text-black">
                          {i.deliveryCharge?.toLocaleString('en-US')}
                        </span>
                              {i.deliveryChargeReturnNumber && (
                                  <span>
                            （退運費申請 -
                            <span className={style.text_dark_info}>
                              {i.deliveryChargeReturnNumber}
                            </span>
                            )
                          </span>
                              )}
                            </td>
                            <td colSpan={4} className="text-end">
                              <FaChevronDown id={`orderId${i.id}`} />
                            </td>
                          </tr>
                          {i.orderItems.map((j, jIndex) => (
                              <tr key={jIndex}>
                                <td>
                                  <input
                                      className={style.maintain_list_checkbox}
                                      type="checkbox"
                                      checked={values.list.indexOf(j.id) !== -1}
                                      onChange={e => {
                                        const val = e.target.checked;
                                        setFieldValue(
                                            'list',
                                            val
                                                ? [...values.list, j.id]
                                                : values.list.filter(k => k !== j.id),
                                        );
                                      }}
                                  />
                                </td>
                                <td>
                                  <div>{j.orderItemNumber}</div>
                                  <div>{j.changeNumber || '--'}</div>
                                </td>
                                <td>
                                  <div>{j.changeHomeDeliveryCName || '--'}</div>
                                  <div>{j.changeHomeDeliveryNumber || '--'}</div>
                                </td>
                                <td>
                                  <div>{j.changeStatusName}</div>
                                  <div>{j.changeCreateTime}</div>
                                  <div>{j.changeStatusTime}</div>
                                </td>
                                <td>
                                  <div>{j.changeReason || '=='}</div>
                                  <div>{j.changeDesc || '=='}</div>
                                  {j.changeDesc && (
                                      <Button
                                          color="link"
                                          className="text-decoration-none"
                                          size="sm"
                                          onClick={() => {
                                            setCurrentDescInfo({
                                              returnDesc: j.changeDesc,
                                              returnReason: j.changeReason,
                                            });
                                            handleToggleDesc();
                                          }}
                                      >
                                        查看完整描述
                                      </Button>
                                  )}
                                </td>
                                <td>
                                  {/* 商品名稱 */}
                                  <div>{j.productname || '--'}</div>
                                  {/* 商品選項 */}
                                  <div>
                                    {j.color || '--'}/{j.size || '--'}
                                  </div>
                                  {/* 商品料號 */}
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                      }}
                                      onClick={() => {
                                        if (Number(j.productType) === 4) {
                                          window.open(
                                              `/#/GiftManagement?page=1&keyword=${
                                                  j.productname || j.freebieCode
                                              }`,
                                              '_blank',
                                          );
                                        } else if (Number(j.productType) === 1) {
                                          window.open(
                                              `/#/ProductControl?page=1&keyword=${
                                                  j.productname || j.productcode
                                              }`,
                                              '_blank',
                                          );
                                        }
                                      }}
                                  >
                                    {Number(j.productType) === 4
                                        ? j.freebieCode || '--'
                                        : j.productcode || '--'}
                                  </div>
                                  {/* 商品屬性 */}
                                  <div>
                                    {Number(j.productType) === 4
                                        ? j.freebieType || '--'
                                        : '商品'}
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div>{j.quantity}</div>
                                  <div className="price text-black fw-normal">
                                    {j.unitPrice.toLocaleString('en-US')}
                                  </div>
                                  {/* 折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end text-primary"
                                      role="button"
                                      onClick={() => {
                                        modal.showBillInfo({
                                          ...j,
                                          orderNumber: i.orderNumber,
                                        });
                                      }}
                                  >
                                    -
                                    <div className="price text-primary fw-normal">
                                      {j?.discountAmountUnit?.toLocaleString('en-US') ||
                                          0}
                                    </div>
                                  </div>
                                  <div className="price text-black fw-normal">
                                    {j?.finalPriceUnit?.toLocaleString('en-US') || 0}
                                  </div>
                                </td>
                                <td className="text-end">
                                  {/* 有無商品贈品 */}
                                  <div>--</div>
                                  {/* 有無活動贈品 */}
                                  <div>--</div>
                                  {/* 折扣活動總折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentDiscountInfo(j.id);
                                        setIsDiscountActivityModalOpen();
                                      }}
                                  >
                                    -
                                    <div
                                        className={`price fw-normal ${style.text_dark_info}`}
                                    >
                                      {(
                                          j.activityDiscountAmountUnit || '0'
                                      )?.toLocaleString('en-US')}
                                    </div>
                                  </div>
                                  {/* 折價券總折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentDiscountInfo(j.id);
                                        setIsDiscountModalOpen();
                                      }}
                                  >
                                    -
                                    <div
                                        className={`price fw-normal ${style.text_dark_info}`}
                                    >
                                      {(
                                          j.couponDiscountAmountUnit || 0
                                      )?.toLocaleString('en-US')}
                                    </div>
                                  </div>
                                  {/* 贈品券活動序號 */}
                                  <div>--</div>
                                </td>
                                <td>
                                  <div className="text-nowrap">
                                    {j.memberPhone || '--'}
                                  </div>
                                  <div>{j.memberLevel || '--'}</div>
                                  <div>{j.changeName || '--'}</div>
                                  <div>{j.changePhone || '--'}</div>
                                </td>
                                <td>
                                  <div>{j.changeCountryName || '--'}</div>
                                  <div>{j.changeZipcode || '--'}</div>
                                  <div>{j.changeCityName || '--'}</div>
                                  <div>{j.changeTownName || '--'}</div>
                                  <div>{j.changeAddress || '--'}</div>
                                </td>
                              </tr>
                          ))}
                        </React.Fragment>
                    ))
                ) : (
                    <TableHasNoData />
                )}
                </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
              current={orderListInfo?.current_page || 1}
              pageSize={orderListInfo?.page_size || 10}
              total={orderListInfo?.total_count || 0}
              onChange={onChangePage}
          />
        </div>
        <OrderReturnDescModal
            open={descOpen}
            toggle={handleToggleDesc}
            info={currentDescInfo}
        />
      </Card>
  );
};
const OrderChangeStep2 = () => {
  const dispatch = useDispatch();
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
      useToggle();
  const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null);

  const query = useQuery();
  const { location } = history;

  const [currentValues, setCurrentValues] = useState({});
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      changeLogisticStatus: 1,
      changeStatus: 2,
      endTime: null,
      isChangeOrder: true,
      keyword: '',
      orderNumber: '',
      orderNumberType: '主單編號',
      page: 1,
      page_size: 10,
      startTime: null,
      timeType: '換貨單成立日期',
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
            ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
            ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime)
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        isChangeOrder: true,
        keyword: `${query.value.keyword}`,
        orderNumber: `${query.value.orderNumber}`,
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime)
            : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      dispatch(getOrderList(body));
      setValues(body);
    } else {
      handleReset();
    }
  }, [location.search]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const handleAction = async (formModel, cb) => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        const { code, message } = await request({
          data: formModel.list,
          method: 'post',
          url: '/Backend/OrderChange/LogisticsPickupFinished',
        });
        if (code !== 200) {
          MessageUtil.alertWanring('發生錯誤！', message);
          return;
        }
        MessageUtil.toastSuccess('修改成功！');
        handleSubmit();
        cb();
      },
      title: '確認取件成功？',
    });
  };
  const handleReject = (formModel, cb) => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        const { code, message } = await request({
          data: formModel.list,
          method: 'post',
          url: '/Backend/OrderChange/LogisticsPickupFailed',
        });
        if (code !== 200) {
          MessageUtil.alertWanring('發生錯誤！', message);
          return;
        }
        MessageUtil.toastSuccess('修改成功！');
        handleSubmit();
        cb();
      },
      title: '確認取件失敗？',
    });
  };

  return (
      <Layout
          pageTitle="換貨取件中-setp2"
          items={[
            { isActive: false, page_name: '換貨訂單管理' },
            { isActive: true, page_name: '換貨取件中-setp2' },
          ]}
      >
        <Alert color="secondary">
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>
            本頁僅出現換貨狀態為【換貨處理中】之訂單
            (排除【物流取件成功】的訂單)
          </span>
          </div>
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>
            點擊【物流取件失敗】，換貨單即會回到【換貨確認-step1】
          </span>
          </div>
        </Alert>
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form>
              <div className="d-flex flex-wrap w-100">
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label text-nowrap mr-2">
                    關鍵字
                  </label>
                  <input
                      type="text"
                      className={`form-control ${style['form-keyword']}`}
                      name="keyword"
                      placeholder="會員電話、收件人、收件人電話"
                      onChange={handleChange}
                      value={values.keyword}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">編號</label>
                  <Input
                      type="select"
                      className={style['form-select-medium']}
                      name="orderNumberType"
                      onChange={handleChange}
                      value={values.orderNumberType}
                      style={{ margin: 0 }}
                  >
                    <option value="主單編號">主單編號</option>
                    <option value="訂單編號">訂單編號</option>
                    <option value="換貨單編號">換貨單編號</option>
                  </Input>
                  <Input
                      type="text"
                      className={style['form-input-order-number']}
                      name="orderNumber"
                      onChange={handleChange}
                      value={values.orderNumber}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">
                    日期區間
                  </label>
                  <Input
                      type="select"
                      className={style['form-select']}
                      name="timeType"
                      onChange={handleChange}
                      value={values.timeType}
                      style={{ margin: 0 }}
                  >
                    <option value="換貨單成立日期">換貨單成立日期</option>
                    <option value="換貨單狀態日期">換貨單狀態日期</option>
                  </Input>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.startTime
                            ? moment(values.startTime).toDate()
                            : moment().subtract(7, 'day').toDate()
                      }
                      name="startTime"
                      onChange={date => {
                        setFieldValue('startTime', date);
                      }}
                      selectsStart
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.endTime
                            ? moment(values.endTime).toDate()
                            : moment().toDate()
                      }
                      name="endTime"
                      onChange={date => {
                        setFieldValue('endTime', date);
                      }}
                      selectsEnd
                      minDate={values.startTime}
                  />
                </div>
              </div>
            </form>
            <div className={style.button_position}>
              <Button
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    handleReset();
                  }}
              >
                取消
              </Button>
              <Button
                  type="button"
                  className={style.modal_button_submit}
                  onClick={() => {
                    setFieldValue('page', 1);
                    handleSubmit();
                  }}
              >
                查詢
              </Button>
            </div>
          </div>
        </Card>
        <TableList
            onAction={handleAction}
            onReject={handleReject}
            onChangePage={handleChangePage}
            setIsDiscountActivityModalOpen={setIsDiscountActivityModalOpen}
            setIsDiscountModalOpen={setIsDiscountModalOpen}
            setCurrentDiscountInfo={setCurrentDiscountInfo}
        />
        {isDiscountModalOpen && (
            <DiscountDetailModal
                isOpen={isDiscountModalOpen}
                toggle={setIsDiscountModalOpen}
                id={currentDiscountInfo}
            />
        )}
        {isDiscountActivityModalOpen && (
            <DiscountActivityDetailModal
                isOpen={isDiscountActivityModalOpen}
                toggle={setIsDiscountActivityModalOpen}
                id={currentDiscountInfo}
            />
        )}
      </Layout>
  );
};
export default OrderChangeStep2;
