import Layout from 'components/Layout';
import { BlockSpinner } from 'components/Spinner';
import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Table,
} from 'reactstrap';
import { getOrderList } from 'redux/actions/data/orderActions';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import request from 'utils/useAxios';
import useFetch from 'utils/useFetch';
import * as Yup from 'yup';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { baseURL, prefix_api_path } from '../redux/actions/data/api_config';
import { orderReturnReasonList } from '../redux/actions/data/orderReturnReasonActions';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const TableList = ({
  onChangePage,
  onAction,
  list,
  flatOrderItems,
  handleCheckNotReturnable,
}) => {
  const { loading, orderListInfo, error } = useSelector(
    state => state.orderList,
  );
  const { orderReturnReasonListInfo } = useSelector(
    state => state.orderReturnReasonList,
  );
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleReset,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: '',
      changeDesc: '',
      cityId: 1,
      countryId: 1,
      name: '',
      orderItems: [],
      phone: '',
      townId: 1,
    },
    async onSubmit(formModel) {
      onAction &&
        (await onAction(
          {
            ...formModel,
            cityId: +formModel.cityId,
            townId: +formModel.townId,
          },
          handleReset,
        ));
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().required('必填'),
      changeDesc: Yup.string().required('必填'),
      name: Yup.string().required('必填'),
      orderItems: Yup.array().of(
        Yup.object().shape({
          changeReason: Yup.string().required('必填'),
          orderItemId: Yup.number().positive(),
          quantity: Yup.number().positive(),
        }),
      ),
      phone: Yup.string().required('必填'),
    }),
  });

  const { data: cityList } = useFetch(
    `${baseURL + prefix_api_path}/Website/City/cityList/1`,
  );

  const { data: townList } = useFetch(
    `${baseURL + prefix_api_path}/Website/City/townList/${values.cityId}`,
  );

  return (
    <>
      <Card className={`${style.card} p-0 mb-5`}>
        <CardHeader className={style.card_header_info}>
          1、勾選欲代替消費者辦理換貨的商品
        </CardHeader>
        <CardBody>
          {loading && isSubmitting && <BlockSpinner />}
          <div
            className={`${style.table_head} p-3 d-flex align-items-center gap-4`}
          >
            <div className="d-flex align-items-center gap-1">
              <input
                className={style.maintain_list_checkbox}
                type="checkbox"
                checked={
                  values.orderItems.length > 0 &&
                  values.orderItems.length ===
                    flatOrderItems.filter(i => !i.notChangeable)?.length
                }
                onChange={e => {
                  const val = e.target.checked;
                  setFieldValue(
                    'orderItems',
                    val ? flatOrderItems.filter(i => !i.notChangeable) : [],
                  );
                }}
              />
              本頁全選
            </div>
          </div>
          <div className="table-responsive">
            <Table hover bordered striped className="mb-5">
              <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
              >
                <tr>
                  <th>選擇</th>
                  <th>申請換貨原因</th>
                  <th>換貨數量</th>
                  <th>
                    <div>訂單編號</div>
                    <div>退換貨狀態</div>
                  </th>
                  <th>
                    <div>商品名稱</div>
                    <div>商品選項</div>
                  </th>
                  <th>
                    <div>商品料號</div>
                    <div>商品屬性</div>
                  </th>
                  <th>
                    <div>數量</div>
                    <div>商品單價</div>
                    <div>折扣金額</div>
                    <div>銷售金額(折扣後)</div>
                  </th>
                  <th>
                    <div>實際出貨數量</div>
                    <div>實際出貨金額</div>
                  </th>
                  <th>
                    <div>有無商品贈品</div>
                    <div>有無活動贈品</div>
                    <div>是否為加購價</div>
                    <div>折扣活動總折扣金額</div>
                    <div>折價券總折扣金額</div>
                  </th>
                  <th>
                    <div>配送方式</div>
                    <div>配送商</div>
                  </th>
                  <th>轉單時間</th>
                  <th>
                    <div>付款方式</div>
                    <div>繳費期限</div>
                    <div>預計出貨日</div>
                    <div>鑑賞期起算日</div>
                  </th>
                  <th>
                    <div>會員編號</div>
                    <div>會員等級</div>
                    <div>收件人</div>
                    <div>收件人電話</div>
                  </th>
                </tr>
              </thead>
              {loading && <TableLoading />}
              {!loading && (
                <tbody>
                  {list?.length > 0 ? (
                    list?.map((i, index) => (
                      <React.Fragment key={i.id}>
                        <tr
                          className="text-nowrap"
                          style={{ backgroundColor: '#BEC0D8' }}
                        >
                          <td></td>

                          <td>
                            總金額：
                            <span className="price text-black">
                              {(i.totalAmount || '0').toLocaleString('en-US')}
                            </span>
                          </td>
                          <td colSpan="2">主單編號：{i.orderNumber}</td>
                          <td colSpan="2">
                            <div>
                              運費：
                              <span className="price text-black">
                                {i.deliveryCharge?.toLocaleString('en-US')}
                              </span>
                              {i.deliveryChargeReturnNumber && (
                                <span>
                                  （退運費申請 -
                                  <span className={style.text_dark_info}>
                                    {i.deliveryChargeReturnNumber}
                                  </span>
                                  )
                                </span>
                              )}
                            </div>
                          </td>
                          <td colSpan={7} className="text-end">
                            <FaChevronDown id={`orderId${i.id}`} />
                          </td>
                        </tr>
                        {i.orderItems.map((j, jIndex) => (
                          <tr key={jIndex}>
                            <td className="align-top">
                              <input
                                className={style.maintain_list_checkbox}
                                type="checkbox"
                                disabled={handleCheckNotReturnable(
                                  j.orderItemStatus,
                                )}
                                onChange={e => {
                                  const val = e.target.checked;
                                  setFieldValue(
                                    'orderItems',
                                    val
                                      ? [
                                          ...values.orderItems,
                                          {
                                            changeReason: '',
                                            orderItemId: j.id,
                                            quantity: 1,
                                          },
                                        ]
                                      : values.orderItems.filter(
                                          k => k.orderItemId !== j.id,
                                        ),
                                  );
                                }}
                                checked={
                                  values.orderItems
                                    .map(k => k.orderItemId)
                                    .indexOf(j.id) !== -1
                                }
                              />
                            </td>
                            <td className="align-top">
                              <Input
                                className="mb-2"
                                type="select"
                                value={
                                  values.orderItems.find(
                                    k => k.orderItemId === j.id,
                                  )?.changeReason || ''
                                }
                                disabled={
                                  handleCheckNotReturnable(j.orderItemStatus) ||
                                  !values.orderItems.find(
                                    k => k.orderItemId === j.id,
                                  )
                                }
                                onChange={e => {
                                  const isExisting = values.orderItems.filter(
                                    item => item.orderItemId === j.id,
                                  );
                                  setFieldValue(
                                    'orderItems',
                                    isExisting.length > 0
                                      ? values.orderItems.map(mlist => {
                                          if (mlist.orderItemId === j.id) {
                                            mlist.changeReason = e.target.value;
                                          }
                                          return mlist;
                                        })
                                      : [
                                          ...values.orderItems,
                                          {
                                            changeReason: e.target.value,
                                            orderItemId: j.id,
                                            quantity: 1,
                                          },
                                        ],
                                  );
                                }}
                              >
                                <option value="" disabled>
                                  請選擇
                                </option>
                                {orderReturnReasonListInfo?.datas?.map(i => (
                                  <option value={i.reason} key={i.id}>
                                    {i.reason}
                                  </option>
                                ))}
                              </Input>
                              {handleCheckNotReturnable(j.orderItemStatus) && (
                                <FormText color="danger">不能換貨</FormText>
                              )}
                              {values.orderItems.find(
                                k => k.orderItemId === j.id,
                              ) &&
                                touched.orderItems?.[
                                  values.orderItems
                                    .map(k => k.orderItemId)
                                    .indexOf(j.id)
                                ] &&
                                !values.orderItems.find(
                                  k => k.orderItemId === j.id,
                                )?.changeReason && (
                                  <FormText color="danger" className="d-block">
                                    必填
                                  </FormText>
                                )}
                            </td>
                            <td className="align-top">
                              <Input
                                className="mb-1"
                                type="select"
                                value={
                                  values.orderItems.find(
                                    k => k.orderItemId === j.id,
                                  )?.quantity || 1
                                }
                                disabled={
                                  handleCheckNotReturnable(j.orderItemStatus) ||
                                  !values.orderItems.find(
                                    k => k.orderItemId === j.id,
                                  )
                                }
                                onChange={e => {
                                  const isExisting = values.orderItems.filter(
                                    item => item.orderItemId === j.id,
                                  );
                                  setFieldValue(
                                    'orderItems',
                                    isExisting.length > 0
                                      ? values.orderItems.map(mlist => {
                                          if (mlist.orderItemId === j.id) {
                                            mlist.quantity = +e.target.value;
                                          }
                                          return mlist;
                                        })
                                      : [
                                          ...values.orderItems,
                                          {
                                            changeReason: '',
                                            orderItemId: j.id,
                                            quantity: +e.target.value,
                                          },
                                        ],
                                  );
                                }}
                              >
                                {Array.from(
                                  { length: j.quantity },
                                  (_, qIndex) => qIndex + 1,
                                ).map(k => (
                                  <option
                                    value={k}
                                    key={k}
                                    className="text-center"
                                  >
                                    {k}
                                  </option>
                                ))}
                              </Input>
                            </td>
                            <td>
                              <div>{j.orderItemNumber || '--'}</div>
                              <div>{j.order_RC_Status || '--'}</div>
                            </td>
                            <td>
                              <div>{j.productname || '--'}</div>
                              <div>{`${j.color || '--'}/${
                                j.size || '--'
                              }`}</div>
                            </td>
                            <td>
                              <div
                                style={{
                                  color: '#0077ff',
                                  cursor: 'pointer',
                                  fontSize: '16px',
                                }}
                                onClick={() => {
                                  sessionStorage.clear();
                                  sessionStorage.setItem(
                                    'keyword',
                                    j.productname,
                                  );
                                  if (Number(j.productType) === 4) {
                                    window.open(
                                      `/#/GiftManagement?page=1&keyword=${
                                        j.productname || j.freebieCode
                                      }`,
                                      '_blank',
                                    );
                                  } else if (Number(j.productType) === 1) {
                                    window.open(
                                      `/#/ProductControl?page=1&keyword=${
                                        j.productname || j.productcode
                                      }`,
                                      '_blank',
                                    );
                                  }
                                }}
                              >
                                {Number(j.productType) === 4
                                  ? j.freebieCode || '--'
                                  : j.productcode || '--'}
                              </div>
                              {/* 商品屬性 */}
                              <div>
                                {Number(j.productType) === 4
                                  ? j.freebieType || '--'
                                  : '商品'}
                              </div>
                            </td>
                            <td className="text-end">
                              {/* 數量 */}
                              <div>{j.quantity || '--'}</div>
                              {/* 商品單價 */}
                              <div className="price text-black fw-normal">
                                {j.unitPrice.toLocaleString('en-US')}
                              </div>
                              {/* 折扣金額 */}
                              <div
                                className="d-flex justify-content-end text-primary"
                                role="button"
                                onClick={() => {
                                  modal.showBillInfo({
                                    ...j,
                                    orderNumber: i.orderNumber,
                                  });
                                }}
                              >
                                -
                                <div className="price text-primary fw-normal">
                                  {j?.discountAmountUnit?.toLocaleString(
                                    'en-US',
                                  ) || 0}
                                </div>
                              </div>
                              {/* 銷售金額(折扣後) */}
                              <div className="price text-black fw-normal">
                                {(j.finalPriceUnit || '0').toLocaleString(
                                  'en-US',
                                )}
                              </div>
                            </td>
                            <td className="text-center">
                              <div>{j.quantity}</div>
                              <div className="price text-black fw-normal">
                                {j?.finalPrice?.toLocaleString('en-US')}
                              </div>
                            </td>
                            <td className="text-end">
                              {/* 有無商品贈品 */}
                              <div>--</div>
                              {/* 有無活動贈品 */}
                              <div>--</div>
                              {/* 折扣活動總折扣金額 */}
                              <div className="d-flex justify-content-end">
                                -
                                <div className="price fw-normal text-black">
                                  {(
                                    j.activityDiscountAmountUnit || '0'
                                  )?.toLocaleString('en-US')}
                                </div>
                              </div>
                              {/* 折價券總折扣金額 */}
                              <div className="d-flex justify-content-end">
                                -
                                <div className="price fw-normal text-black">
                                  {(
                                    j.couponDiscountAmountUnit || '0'
                                  )?.toLocaleString('en-US')}
                                </div>
                              </div>
                              <div>--</div>
                            </td>
                            <td>
                              <div>{j.logisticsWayName || '--'}</div>
                              <div>{j.returnHomeDeliveryCName || '--'}</div>
                            </td>
                            <td>{i.orderTime}</td>
                            <td>
                              <div>{i.payWayName}</div>
                              <div>{j.expireDate_ATM || '--'}</div>
                              <div>{i.expectedDeliveryDate || '--'}</div>
                              <div>{j.freeReturnDateStart || '--'}</div>
                            </td>
                            <td>
                              <div>{j.memberPhone || '--'}</div>
                              <div>{j.memberLevel || '--'}</div>
                              <div>{j.name || '--'}</div>
                              <div>{j.phone || '--'}</div>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))
                  ) : (
                    <TableHasNoData />
                  )}

                  {error && <TableHasNoData />}
                </tbody>
              )}
            </Table>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              current={orderListInfo?.current_page || 1}
              pageSize={orderListInfo?.page_size || 10}
              total={orderListInfo?.total_count || 0}
              onChange={onChangePage}
            />
          </div>
          {flatOrderItems?.filter(i => !i.notChangeable)?.length > 0 && (
            <FormGroup>
              <Label style={{ width: 150 }}>
                <span className="text-danger">* </span>
                換貨描述
              </Label>
              <Input
                type="textarea"
                rows={5}
                name="changeDesc"
                onChange={handleChange}
                value={values.changeDesc}
              />
              {errors.changeDesc && touched.changeDesc && (
                <FormText color="danger" className="d-block">
                  {errors.changeDesc}
                </FormText>
              )}
            </FormGroup>
          )}
        </CardBody>
      </Card>
      {flatOrderItems?.filter(i => !i.notChangeable)?.length > 0 && (
        <>
          <Card className={`${style.card} p-0 mb-5`}>
            <CardHeader className={style.card_header_info}>
              2、換貨資訊
            </CardHeader>
            <CardBody>
              <FormGroup row>
                <Label style={{ width: 150 }}>
                  <span className="text-danger">* </span>聯絡人姓名
                </Label>
                <Col>
                  <Input
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                  />
                  {errors.name && touched.name && (
                    <FormText color="danger" className="d-block">
                      {errors.name}
                    </FormText>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label style={{ width: 150 }}>
                  <span className="text-danger">* </span>手機號碼
                </Label>
                <Col>
                  <Input
                    name="phone"
                    onChange={handleChange}
                    value={values.phone}
                  />
                  {errors.phone && touched.phone && (
                    <FormText color="danger" className="d-block">
                      {errors.phone}
                    </FormText>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label style={{ width: 150 }}>
                  <span className="text-danger">* </span>聯絡地址
                </Label>
                <Col>
                  <div className="d-flex gap-2">
                    <Input
                      type="select"
                      name="cityId"
                      onChange={handleChange}
                      value={values.cityId}
                    >
                      {cityList?.map(i => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                    </Input>
                    <Input
                      type="select"
                      name="townId"
                      onChange={handleChange}
                      value={values.townId}
                    >
                      {townList?.map(i => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <Input
                    className="mt-2"
                    placeholder="輸入詳細地址"
                    name="address"
                    onChange={handleChange}
                    value={values.address}
                  />
                  {errors.address && touched.address && (
                    <FormText color="danger" className="d-block">
                      {errors.address}
                    </FormText>
                  )}
                </Col>
              </FormGroup>
            </CardBody>
          </Card>
          <Button
            type="submit"
            className={style.modal_button_submit}
            onClick={handleSubmit}
          >
            確認換貨
          </Button>
        </>
      )}
    </>
  );
};
const OrderChangeCreate = () => {
  const dispatch = useDispatch();
  const { orderListInfo } = useSelector(state => state.orderList);
  const [pageIsChanged, setPageIsChanged] = useState(false);
  const [showTable, setShowTable] = useState(false);
  useEffect(() => {
    dispatch(
      orderReturnReasonList(
        {
          page: 1,
          page_size: 10,
        },
        '換貨',
      ),
    );
  }, []);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    touched,
    errors,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      isOktoChange: true,
      orderNumber: '',
      orderNumberType: '主單編號',
      page: 1,
      page_size: 10,
    },
    async onSubmit(formModel) {
      setShowTable(false);
      const params = {
        ...formModel,
        page: pageIsChanged ? formModel.page : 1,
      };
      CommonUtil.consoleLog({ anno: 'request body', data: params });
      await dispatch(getOrderList(params));
      setShowTable(true);
      setPageIsChanged(false);
    },
    validationSchema: Yup.object().shape({
      orderNumber: Yup.string().required('必填'),
    }),
  });

  const handleChangePage = n => {
    setFieldValue('page', n);
    setPageIsChanged(true);
    handleSubmit();
  };

  const handleCheckNotReturnable = orderItemStatus => {
    return orderItemStatus !== 4 && orderItemStatus !== 7;
  };

  const flatOrderItems =
    orderListInfo?.datas
      ?.map(i =>
        i.orderItems.map(j => ({
          changeReason: '',
          notChangeable: handleCheckNotReturnable(j.orderItemStatus),
          orderItemId: j.id,
          quantity: 1,
        })),
      )
      .flat() || [];

  const handleAction = async (formModel, cb) => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        const { code, message } = await request({
          data: formModel,
          method: 'post',
          url: '/Backend/OrderChange/AddChangeOrderNew',
        });
        if (code !== 200) {
          MessageUtil.alertWanring('申請失敗', message);
          return;
        }
        MessageUtil.toastSuccess('儲存成功！');
        handleSubmit();
        cb();
      },
      title: '確認申請換貨？',
    });
  };
  return (
    <Layout
      pageTitle="新增換貨單"
      items={[
        { isActive: false, page_name: '換貨訂單管理' },
        { isActive: true, page_name: '新增換貨單' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />
          <span className={style.fs_14}>
            本頁僅出現主單狀態有出現過【已完成】的訂單。
          </span>
        </div>
        <div>
          <AiOutlineInfoCircle />
          <span className={style.fs_14}>
            當訂單狀態是【退貨處理中】、【換貨處理中】、【已退貨】不能換貨。
          </span>
        </div>
      </Alert>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className="d-flex flex-wrap w-100">
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">編號</label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  name="orderNumberType"
                  onChange={handleChange}
                  value={values.orderNumberType}
                  style={{ margin: 0 }}
                >
                  <option value="主單編號">主單編號</option>
                  <option value="訂單編號">訂單編號</option>
                </Input>
                <Input
                  type="text"
                  className={style['form-input-order-number']}
                  name="orderNumber"
                  onChange={handleChange}
                  value={values.orderNumber}
                  invalid={touched.orderNumber && errors.orderNumber}
                />
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
                handleSubmit(values);
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      {showTable && (
        <TableList
          onChangePage={handleChangePage}
          onAction={handleAction}
          list={orderListInfo?.datas}
          orderListInfo={orderListInfo}
          flatOrderItems={flatOrderItems}
          handleCheckNotReturnable={handleCheckNotReturnable}
        />
      )}
    </Layout>
  );
};
export default OrderChangeCreate;
